import * as $GoodsCategoryApi from '@/api/goodsCategory';
import * as $GoodsApi from '@/api/goods';

export class GoodsCategoryProxy {
  children: GoodsCategoryProxy[] = [];
  props: any;

  static map: any = {};

  constructor(
    public self_goods = '',
    public id = 0,
    public label = '',
    public value = '',
    public level = 0,
    public leaf = level === 3,
  ) {
    if (this.id === 0) {
      this.load();
      this.props = {
        lazy: true,
        emitPath: false,
        checkStrictly: true,
        expandTrigger: 'hover',
        lazyLoad: (node, resolve) => {
          if (!node.data) return resolve();
          node.data.load().then(resolve);
        },
      };
    }
    if (this.leaf) this.children = null!;
  }

  load() {
    if (this.leaf || this.children.length) return Promise.resolve(this.children);

    return $GoodsCategoryApi.getGoodsCategoryLevelList(this.id, null).then(resp => {
      if (!resp) return [];
      console.log(this.self_goods);

      const dataList = resp.filter(item => {
        if (this.self_goods === '9') {
          return item.name === '蛋糕'
        } else {
          return item;
        }
      })
      this.children = dataList.map(item => {
        const proxy = new GoodsCategoryProxy(
          this.self_goods,
          item.category_id,
          item.name,
          item.category_path,
          this.level + 1,
        );
        GoodsCategoryProxy.map[item.category_path] = proxy;
        return proxy;
      });

      return this.children;
    });
  }

  change(path) {
    GoodsCategoryProxy.map[path].load();
  }
}

class JDGoodsCategoryProxy {
  categories: any[] = [];
  loading = false;

  private rebuildData(data: any[]) {
    return data.map(item => {
      if (item.children.length > 0) {
        item.children = this.rebuildData(item.children);
      } else {
        item.leaf = true;
      }
      return {
        label: item.name,
        value: item.cat_id,
        leaf: item.leaf,
        children: !!item.children.length && item.children,
      };
    }).sort((a, b) => a.value - b.value);
  }

  constructor() {
  }

  load() {
    if (this.loading) return Promise.resolve();
    this.loading = true;

    return $GoodsApi.getJDGoodsCategoryLevelList('2').then(resp => {
      this.loading = false;

      if (!resp) return;
      this.categories = this.rebuildData(resp);
    });
  }

  tryReload() {
    if (!this.categories.length) {
      this.load();
    }
  }
}

export const $goodsCategory = {
  JD: new JDGoodsCategoryProxy(),
  create: (self_goods = '') => new GoodsCategoryProxy(self_goods),
};
