/**
 * Created by Andste on 2018/7/2.
 * base    : 基础业务API
 * buyer   : 买家API
 * seller  : 商家中心API
 * admin   ：后台管理API
 */
const env = process.server
  ? process.env
  : (global.window ? window.__NUXT__.state.env : {});

let dev;

if (location.host.includes('two')||location.host.includes('deyong9') || location.host.includes('172.20') || location.host.includes('172.18') || location.host.includes('192.168')|| location.host.includes('10.10') ) {
  dev = {
    base: 'https://testtwoapifcbase.sshlqf.com',
    buyer: 'https://testtwoapifcbuyer.sshlqf.com',
    seller: 'https://testtwoapifcseller.sshlqf.com',
    admin: 'https://testtwoapifcadmin.sshlqf.com'
  };
} else {
  dev = {
    base: 'https://testapifcbase.sshlqf.com',
    buyer: 'https://testapifcbuyer.sshlqf.com',
    seller: 'https://testapifcseller.sshlqf.com',
    admin: 'https://testapifcadmin.sshlqf.com'
  };
}

const pro = {
  base: env.API_BASE || 'https://apibase.sshlqf.com',
  buyer: env.API_BUYER || 'https://apibuyer.sshlqf.com',
  seller: env.API_SELLER || 'https://apiseller.sshlqf.com',
  admin: env.API_ADMIN || 'https://apiadmin.sshlqf.com',
}

module.exports = {
  dev,
  pro,
};
