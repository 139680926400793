/**
 * 订单相关API
 */

import request from "@/utils/request";

/**
 * 查询店铺额外功能配置
 * @param params
 */
export function getShopExtAuth() {
  return request({
    url: "seller/shops/getShopExtAuth",
    method: "get",
    loading: false,
  });
}

/**
 * 获取开卡核销ids
 * @param params
 */
export function getTradeIds(params) {
  return request({
    url: "seller/card/underWrite/getTradeIds",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 获取退卡核销ids
 * @param params
 */
export function getRefundIds(params) {
  return request({
    url: "seller/card/underWrite/getRefundIds",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 获取订单列表
 * @param params
 * @returns {Promise<any>}
 */
export function getOrderList(params) {
  return request({
    url: "seller/trade/orders",
    method: "get",
    loading: false,
    params
  });
}


/**
 * 获取卡券退单列表
 * @param params
 */
export function getRefundOrderList(params) {
  return request({
    url: "seller/trade/orders/seller/cards/refund/query",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 获取卡券订单列表
 * @param params
 */
export function getCardsOrderList(params) {
  return request({
    url: "seller/trade/orders/seller/cards/query",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 获取卡券核销列表
 * @param params
 */
export function getCardsUnderWrite(params) {
  return request({
    url: "seller/card/underWrite/sell",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 订单&&退单查看详情页
 * @param params
 */
export function getInfoUnderWrite(params) {
  return request({
    url: "seller/card/underWrite/getInfo",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 卡券核销--订单核销记录
 * @param params
 */
export function getRemarkUnderWrite(params) {
  return request({
    url: "seller/card/underWrite/trade/remark",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 卡券核销--退单核销记录
 * @param params
 */
export function getRefundRemarkUnderWrite(params) {
  return request({
    url: "seller/card/underWrite/refund/remark",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 卡券核销--退卡回显
 * @param params
 */
export function getCloseCardsTypeChange(params) {
  return request({
    url: "seller/card/underWrite/getCloseCardsTypeChange",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 卡券核销--开卡回显
 * @param params
 */
export function getOpenCardsTypeChange(params) {
  return request({
    url: "seller/card/underWrite/getOpenCardsTypeChange",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 卡券核销--核销确定
 * @param params
 */
export function editCurrPrice(params) {
  return request({
    url: "seller/card/underWrite/editCurrPrice",
    method: "put",
    loading: false,
    data: params
  });
}

/**
 * 卡券核销--开卡确定
 * @param params
 */
export function openAllCards(params) {
  return request({
    url: "seller/card/underWrite/openAllCards",
    method: "put",
    loading: false,
    data: params
  });
}

/**
 * 卡券核销--退卡确定
 * @param params
 */
export function closeAllCards(params) {
  return request({
    url: "seller/card/underWrite/closeAllCards",
    method: "put",
    loading: false,
    data: params
  });
}

/**
 * 卡券核销--备注更新
 * @param params
 */
export function updateRemark(params) {
  return request({
    url: "seller/card/underWrite/updateRemark",
    method: "put",
    loading: false,
    data: params
  });
}

/**
 * 获取备注列表
 * @param params
 */
export function getRemarkList(params) {
  return request({
    url: "seller/trade/orderRemark",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 修改订单备注
 * @param params
 */
export function editOrderRemark(params) {
  return request({
    url: "seller/trade/orderRemark/save",
    method: "post",
    loading: false,
    data: params
  });
}

/**
 * 取消订单
 * @param trade_sn
 * @param card_code
 * @param combo_id
 * @param reason
 */
export function cancelOrder(params) {
  return request({
    url: "seller/card/combo/cartCancelOrder",
    method: "put",
    loading: false,
    data: params
  });
}

/**
 * 取消拣货
 */
export function cancelPicking(params) {
  return request({
    url: "seller/trade/orders/expressBack",
    method: "POST",
    loading: false,
    data: params
  });
}

/**
 * 获取物流信息
 * @param order_sn
 */
export function getLogisticsList(order_sn) {
  return request({
    url: `seller/trade/orders/seller/${order_sn}/logisticsList`,
    method: 'get',
    loading: false,
  })
}

/**
 * 修改物流信息
 * @param data
 */
export function editLogistics(data) {
  return request({
    url: 'seller/trade/orders/seller/logisticsList',
    method: 'put',
    loading: false,
    headers: {'Content-Type': 'application/json'},
    data,
  })
}

/**
 * 根据订单sn获取订单详情
 * @param sn
 * @returns {Promise<any>}
 */
export function getOrderDetail(sn) {
  return request({
    url: `seller/trade/orders/shop/${sn}`,
    method: "get",
    loading: false
  });
}

/**
 * 获取供应商订单列表
 * @param params
 * @returns {Promise<any>}
 */
export function getSellerOrderList(params) {
  return request({
    url: "seller/trade/orders/sellerOrderList",
    method: "get",
    loading: false,
    params
  });
}
/**
 * 获取包裹信息
 * @param params
 */

export function getLogistics(params) {
  return request({
    url: "seller/trade/orders/getLogistics",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 根据供应商订单sn获取订单详情
 * @param sn
 * @returns {Promise<any>}
 */
export function getSellerOrderDetail(sn) {
  return request({
    url: `seller/trade/orders/seller/${sn}`,
    method: "get",
    loading: false
  });
}

/**
 * 调整价格
 * @param sn
 * @returns {Promise<any>}
 */
export function updateOrderPrice(sn, params) {
  return request({
    url: `seller/trade/orders/${sn}/price`,
    method: "put",
    loading: false,
    data: params
  });
}

/**
 * 修改收货人信息
 * @param sn
 * @param params
 * @returns {Promise<any>}
 */
export function updateConsigneeInfo(sn, params) {
  return request({
    url: `seller/trade/orders/${sn}/address`,
    method: "put",
    loading: false,
    data: params
  });
}

/**
 * 确认收款
 * @param sn
 * @param params
 * @returns {Promise<any>}
 */
export function confirmGetAmount(sn, params) {
  return request({
    url: `seller/trade/orders/${sn}/pay`,
    method: "post",
    loading: false,
    data: params
  });
}

/**
 * 发货
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function deliveryGoods(sn, params) {
  return request({
    url: `seller/trade/orders/${sn}/delivery`,
    method: "post",
    loading: false,
    data: params
  });
}

/**
 * 取消订单
 * @param params
 * @returns {Promise<any>}
 */
export function cancelTrade(params) {
  return request({
    url: `seller/trade/orders/cancelTrade`,
    method: "post",
    loading: true,
    data: params
  });
}

/**
 * 查询快递物流信息
 * @param params
 * @returns {Promise<any>}
 */
export function getLogisticsInfo(params) {
  return request({
    url: `seller/express`,
    method: "get",
    loading: true,
    params
  });
}

/**
 * 生成电子面单
 * @param params
 * @returns {Promise<any>}
 */
export function generateElectronicSurface(params) {
  return request({
    url: `seller/waybill`,
    method: "get",
    loading: false,
    params
  });
}

/**
 * 获取订单流程图数据
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getStepList(ids) {
  return request({
    url: `seller/trade/orders/${ids}/flow`,
    method: "get",
    loading: false
  });
}

/**
 * 导入订单发货
 * @param params
 * @returns {Promise<any>}
 */
export function importAndShipSellerOrder(data) {
  return request({
    url: `seller/trade/orders/importAndShipSellerOrderNew`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    loading: false,
    data
  });
}

/**
 * 导入订单发货接口请求次数
 * @param params
 * @returns {Promise<any>}
 */
export function importOrderRequest(data) {
  return request({
    url: `seller/trade/orders/${data}`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    loading: false
  });
}

/**
 * 轮询是否完成导入订单发货
 * @param params
 * @returns {Promise<any>}
 */
export function pollImportResult(params) {
  return request({
    url: `seller/trade/orders/getRequestNumber`,
    method: "get",
    loading: false,
    params
  });
}

/**
 * 导入历史物流信息
 * @param params
 * @returns {Promise<any>}
 */
export function importAndAddDeliveryInfo(data) {
  return request({
    url: `seller/trade/orders/importAndAddDeliveryInfo`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    // loading: false,
    data
  });
}

/**
 * 取消订单
 * @param sn
 * @param params
 * @returns {Promise<any>}
 */
export function confirmCancelled(sn, params) {
  return request({
    url: `seller/trade/orders/${sn}/cancelled`,
    method: "post",
    loading: false,
    data: params
  });
}

export function getConsumerRank(params) {
  return request({
    url: `seller/trade/orders/getConsumerRank`,
    method: "get",
    params
  });
}

//确认发货
export function confirmStock(order_sns, params) {
  return request({
    url: `seller/trade/orders/${order_sns}/shipConfirm`,
    method: "post",
    data: params
  });
}

//订单商品查询
export function getOrderItemsList(order_sn) {
  return request({
    url: `seller/trade/orders/seller/${order_sn}/getOrderItemsList`,
    method: "get"
  });
}
//订单商品查询（2023.8.25）
export function newGetOrderItemsList(order_sn,goods_id) {
  return request({
    url: `seller/trade/orders/seller/${order_sn}/${goods_id}/getOrderItemsList`,
    method: "get"
  });
}
//确认发货
export function deliveryNew(params) {
  return request({
    url: `seller/trade/orders/deliveryNew`,
    headers: { "Content-Type": "application/json" },
    method: "post",
    data: params
  });
}

//查看物流信息
export function getExpressListBySkuId(params) {
  return request({
    url: `seller/express/getExpressListBySkuId`,
    method: "get",
    params
  });
}

//查看物流信息2
export function getExpressListBySkuIdNew(params) {
  return request({
    url: `seller/express/getExpressListBySkuIdNew`,
    method: "get",
    params
  });
}

//导出日志列表
export function getExportLogList(params) {
  return request({
    url: `/seller/trade/orders/getExportLogList`,
    method: "get",
    params
  });
}

//下载导出日志
export function exportLogList(params) {
  return request({
    url: `/seller/trade/orders/exportLogList`,
    method: "get",
    loading: false,
    params
  });
}

//企业端服务订单
export function getServiceOrder(params) {
  return request({
    url: `/seller/trade/serviceOrder`,
    method: "get",
    loading: false,
    params
  });
}

//企业端服务订单详情
export function getServiceOrderdId(id) {
  return request({
    url: `/seller/trade/serviceOrder/${id}`,
    method: "get",
    loading: false
  });
}

//企业端服务订单详情
export function getAccountFlowId(id) {
  return request({
    url: `/account/accountFlow/${id}`,
    method: "get",
    loading: false
  });
}

//京东售后申请结果查询  订单编号sn
export function getJDAfterSale(sn) {
  return request({
    url: `/seller/after-sales/getJDAfterSale/${sn}`,
    method: "get",
    loading: false
  });
}

// 站点转为售后订单
export function getApply(params) {
  return request({
    url: "seller/after-sales/refunds/apply",
    method: "post",
    loading: false,
    params
  });
}

// 查询交易明细
export function getTradeInfo(trade_sn) {
  return request({
    url: `seller/trade/orders/tradeInfo/${trade_sn}`,
    method: "get",
    loading: false
  });
}

// 查询物流信息
export function getLogisticsOrder(params) {
  return request({
    url: `seller/trade/orders/getLogisticsOrder`,
    method: "get",
    loading: false,
    params
  });
}

/**
 * 获取当前企业是否拥有自有商品
 */
export function getEnterpriseISZiYou() {
  return request({
    url: '/seller/shops/getOwnGoodsTab',
    method: 'get'
  })
}

/**
 * 获取卡券信息
 * @param params
 */
export function getComboInfo(params) {
  return request({
    url: '/seller/trade/orders/getCardInfoTh',
    method: 'get',
    params
  })
}
/**
 * 查询核销记录列表
 * @param {*} params 
 * @returns 
 */
export function getVerifyRecordList(params) {
  return request({
    url: '/seller/verifyRecord/list',
    method: 'get',
    params
  })
}

/**
 * 站点修改备注信息
 * @param {*} params 
 * @returns 
 */
export function updateShopRemake (params) {
  return request({
    url: '/seller/trade/orders/updateShopRemake',
    method: 'get',
    params
  })
}