import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/views/layout/Layout';
// import comboCardShopRouterMap from '@/router/asyncRouterMap/combo-card-shop';

Vue.use(Router);

export const constantRouterMap = [
  {
    path: '/404',
    component: () => import('@/views/errorPage/404'),
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/pages/auth/pages/login2'),
    hidden: true
  },
  {
    path: '/no-sale-open',
    component: () => import('@/views/errorPage/no-sale-open'),
    name: 'noSaleOpen',
    hidden: true
  },
  {
    path: '/hub',
    component: () => import("@/views/Hub"),
    name: "Hub",
    hidden: true
  },
  // 开发-管理后台菜单使用,已在左侧菜单隐藏
  {
    path: '/development',
    component: Layout,
    redirect: '/development/tool-manage/menu-manage',
    name: 'development',
    meta: {
      title: 'development',
      icon: 'page'
    },
    hidden: true,
    children: [
      {
        path: '/development/tool-manage',
        component: () => import('@/views/development/tool-manage/index'),
        redirect: '/development/tool-manage/menu-manage',
        name: 'toolManage',
        meta: { title: 'toolManage' },
        children: [
          {
            path: 'menu-manage',
            component: () => import('@/views/development/tool-manage/menuManage'),
            name: 'menuManage',
            meta: { title: 'menuManage' }
          }
        ]
      },
    ]
  },
]

export const asyncRouterMap = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: {
      title: 'sencond',
      icon: 'home'
    },
    name: 'sencond',
    children: [{
      path: 'dashboard',
      component: () => import('@/views/dashboard/index'),
      name: 'dashboard',
      meta: { title: 'sencond' }
    }]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'first',
    meta: {
      title: 'first',
      icon: 'home'
    },
    children: [
      {
        path: 'home',
        component: () => import('@/views/dashboard/home'),
        name: 'home',
        meta: { title: 'home' }
      },
      {
        path: 'loginLog',
        component: () => import('@/views/log/loginLog'),
        name: 'loginLog',
        meta: { title: 'loginLog' }
      },
      {
        path: 'loginLoga',
        component: () => import('@/views/log/loginLog'),
        name: 'loginLoga',
        meta: { title: 'loginLoga' }
      },
      {
        path: 'handle',
        component: () => import('@/views/log/handle'),
        name: 'handleLog',
        meta: { title: 'handleLog' }
      },
      {
        path: 'handlea',
        component: () => import('@/views/log/handle'),
        name: 'handleLoga',
        meta: { title: 'handleLoga' }
      },
      {
        path: 'compromote',
        component: () => import('@/views/log/comPromote'),
        name: 'compromote',
        meta: { title: 'compromote' }
      }
    ]
  },

  // 页面管理
  {
    path: '/',
    component: Layout,
    redirect: '/floor',
    name: 'page',
    meta: {
      title: 'page',
      icon: 'page'
    },
    children: [
      {
        path: 'floor-preload',
        component: () => import('@/pages/floor/floor-preload'),
        name: 'mobileFloorManageA',
        meta: { title: 'mobileFloorManageA' }
      },
      {
        path: 'floor-preload/:type/:id',
        component: () => import('@/pages/floor/floor-preload'),
        hidden: true
      },
      {
        path: 'floor',
        component: () => import('@/pages/floor/renovation/floor-renovation'),
        name: 'floor',
        hidden: true
      },
      {
        path: 'floor/:type/:id',
        component: () => import('@/pages/floor/renovation/floor-renovation'),
        name: 'floorWidthParams',
        hidden: true
      },
      {
        path: 'child',
        component: () => import('@/views/page/page-setting/childFloor'),
        name: 'childFloor',
        meta: { title: 'childFloor' }
      },
      {
        path: 'special',
        component: () => import('@/views/page/special/list'),
        name: 'specialList',
        meta: { title: 'specialList' }
      },
      {
        path: 'loginJ',
        component: () => import('@/views/page/journal/loginJournal'),
        name: 'loginJournal',
        meta: { title: 'loginJournal' }
      },
      {
        path: 'operation',
        component: () => import('@/views/page/journal/operationJournal'),
        name: 'operationJournal',
        meta: { title: 'operationJournal' }
      },
      {
        path: 'special-edit',
        component: () => import('@/views/page/special/edit'),
        name: 'specialEdit',
        meta: { title: 'specialEdit' },
        hidden: true
      },
      {
        path: 'focus',
        component: () => import('@/views/page/mobile-decoration/mobileFocusManage'),
        name: 'mobileFocusManage',
        meta: { title: 'mobileFocusManage' }
      },
      {
        path: 'poverty-alleviation',
        component: () => import('@/views/page/mobile-decoration/povertyAlleviation'),
        name: 'povertyAlleviationWap',
        meta: { title: 'povertyAlleviationWap' }
      },
      {
        path: '/page/page-setting',
        component: () => import('@/views/page/page-setting/index'),
        redirect: '/page/page-setting/pc-site-menu',
        name: 'pageSetting',
        meta: { title: 'pageSetting' },
        children: [
          {
            path: 'mobile-site-menu',
            component: () => import('@/views/page/page-setting/mobileSiteMenu'),
            name: 'mobileSiteMenu',
            meta: { title: 'mobileSiteMenu' }
          },
          {
            path: 'hot-keyword',
            component: () => import('@/views/page/page-setting/hotKeyword'),
            name: 'hotKeyword',
            meta: { title: 'hotKeyword' }
          }
        ]
      }
    ]
  },
  // 集市
  {
    path: '/markets',
    component: Layout,
    redirect: '/markets/market',
    name: 'markets',
    meta: {
      title: 'markets',
      icon: 'market'
    },
    children: [
      {
        path: 'market',
        component: () => import('@/views/page/pc-decoration/market'),
        name: 'market',
        meta: { title: 'market' }
      },
      {
        path: 'goods',
        component: () => import('@/views/page/pc-decoration/goods'),
        name: 'marketGoods',
        hidden: true,
        meta: { title: 'marketGoods' }
      },

      {
        path: 'shopMarket',
        component: () => import('@/views/page/pc-decoration/goods'),
        name: 'shopMarket',
        hidden: true,
        meta: { title: 'marketGoods' }
      },
      {
        path: 'publish',
        component: () => import('@/views/page/pc-decoration/privateGoodsPublish'),
        name: 'privateGoodsPublish',
        hidden: true,
        meta: { title: 'privateGoodsPublish' }
      },
      {
        path: 'good',
        component: () => import('@/views/page/pc-decoration/good'),
        name: 'marketGood',
        hidden: true,
        meta: { title: 'marketGood' }
      },
      {
        path: 'gooda',
        component: () => import('@/views/page/pc-decoration/good'),
        name: 'marketGooda',
        hidden: true,
        meta: { title: 'marketGooda' }
      },
      {
        path: 'goodb',
        component: () => import('@/views/page/pc-decoration/good'),
        name: 'marketGoodb',
        hidden: true,
        meta: { title: 'marketGoodb' }
      },
      {
        path: 'privateGoods/:nav/:type',
        component: () => import('@/views/page/pc-decoration/privateGoods'),
        name: 'privateGoods',
        hidden: true,
        meta: {
          title: 'privateGoods',
          activeNames: ['market']
        }
      },
      {
        path: 'good-edit',
        component: () => import('@/views/goods/goodsPublish'),
        name: 'goodEdit1',
        meta: {
          title: 'goodEdit',
          noCache: true
        },
        hidden: true
      },
    ]
  },
  // 商品管理（商家）
  {
    path: '/shop-goods',
    component: Layout,
    redirect: '/shop-goods/shop-goods-list',
    name: 'shopGoods',
    meta: {
      title: 'shopGoods',
      icon: 'goods'
    },
    children: [
      // 店铺商品
      {
        path: 'shop-goods-list',
        component: () => import('@/views/shopGoods/shopGoodsList'),
        name: 'shopGoodsList',
        meta: { title: 'shopGoodsList' }
      },
      {
        path: `edit-gift-goods/:id?`,
        component: () => import(`@/views/shopGoods/editGiftGoods`),
        name: `editGiftGoods`,
        meta: {
          title: 'editGiftGoods',
          noCache: true,
          activeNames: ['shopGoodsList']
        },
        hidden: true
      },
      {
        path: 'goodsListzd',
        component: () => import('@/views/shopGoods/goodsListzd'),
        name: 'goodsListzd',
        meta: { title: 'goodsListzd' },
        hidden: true
      },
      // 集市精选
      {
        path: 'market',
        component: () => import('@/views/shopGoods/market'),
        name: 'market1',
        meta: { title: 'market1' }
      },
      {
        path: 'goods',
        component: () => import('@/views/shopGoods/goods'),
        name: 'shopGoodsa',
        hidden: true,
        meta: { title: 'shopGoodsa' }
      },
      {
        path: 'goods/:nav/:type',
        component: () => import('@/views/shopGoods/goods'),
        name: 'shopGoodsa',
        hidden: true,
        meta: { title: 'shopGoodsa', activeNames: ['market1'] }
      },
      {
        path: 'good/:from',
        component: () => import('@/views/page/pc-decoration/good'),
        name: 'marketGooda',
        hidden: true,
        meta: { title: 'marketGooda' }
      },
      // 智能运营
      {
        path: 'intel-operation',
        component: () => import('@/views/shopGoods/intelOperation'),
        name: 'intelOperation',
        meta: {
          title: 'intelOperation'
        }
      },
      {
        path: 'publish',
        component: () => import('@/views/page/pc-decoration/privateGoodsPublish'),
        name: 'privateGoodsPublish',
        hidden: true,
        meta: {
          title: 'privateGoodsPublish',
          noCache: true,
          activeNames: ['shopGoodsList']
        }
      },
      {
        path: 'tag-add/:tag_id',
        component: () => import('@/views/goods/tagAdd'),
        name: 'shopTagAdd',
        meta: { title: 'shopTagAdd' },
        hidden: true
      },
      {
        path: 'shop-good-publish',
        component: () => import('@/views/shopGoods/shopGoodPublish'),
        name: 'shopGoodPublish',
        meta: {
          title: 'shopGoodPublish',
          noCache: true,
          activeNames: ['shopGoodsList']
        },
        hidden: true
      },
      // 商品改价记录
      {
        path: 'price-change-record',
        name: 'PriceChangeRecord',
        component: () => import('@/pages/goods/pages/PriceChangeRecord/PriceChangeRecord'),
        meta: { title: 'PriceChangeRecord' }
      },
    ]
  },

  // 2.0 之前的用户路由
  {
    path: '/users',
    component: Layout,
    name: 'users',
    redirect: '/users/user-list',
    meta: {
      title: 'users',
      icon: 'user'
    },
    children: [
      {
        path: 'user-list',
        component: () => import('@/views/users/userList'),
        name: 'userList',
        meta: { title: 'userList' }
      },
      {
        path: 'fzadmin',
        component: () => import('@/views/users/fzadmin'),
        name: 'fzadmin',
        meta: { title: 'fzadmin' }
      },
      {
        path: 'userschong',
        component: () => import('@/views/users/userschong'),
        name: 'userschong',
        meta: { title: 'userschong' }
      },
      {
        path: 'user-msg',
        component: () => import('@/views/users/usersMsg'),
        name: 'usersMsg',
        hidden: true,
        meta: {
          title: 'usersMsg',
          activeNames: ['userList'],
        }
      },
      {
        path: 'chongMsg',
        component: () => import('@/views/users/chongMsg'),
        name: 'chongMsg',
        hidden: true,
        meta: {
          title: 'chongMsg',
          activeNames: ['userschong']
        }
      }
    ]
  },

  // 订单管理
  {

    path: '/order',
    component: Layout,
    name: 'order',
    redirect: '/order/order-list',
    meta: {
      title: 'order',
      icon: 'order'
    },
    children: [
      // 供应商
      // 现金商城-订单列表
      {
        path: 'order-list',
        component: () => import('@/views/order/orderList/orderList'),
        name: 'orderList',
        meta: { title: 'orderList' },
      },
      // 自有订单列表
      {
        path: 'own-order-list',
        component: () => import('@/views/order/orderList/ownOrdersList'),
        name: 'ownOrdersList',
        meta: { title: 'ownOrdersList' },
      },
      // 现金商城-维权订单
      {
        path: 'refund-list',
        component: () => import('@/views/order/refundList/refundList'),
        name: 'refundList',
        meta: { title: 'refundList' },
      },
      // 自有维权订单
      {
        path: 'own-refund-list',
        component: () => import('@/views/order/refundList/ownRefundList'),
        name: 'ownRefundList',
        meta: { title: 'ownRefundList' },
      },
      {
        path: 'groupBuying-List', // 站点端拼团订单
        component: () => import('@/views/order/groupBuyingList/groupBuyingList'),
        name: 'activityGroupBuyOrder',
        meta: {
          title: 'groupBuyingList'
        }
      },
      {
        path: 'groupBuyingDetail/:sn?', // 站点端拼团订单详情
        component: () => import('@/views/order/groupBuyingList/groupBuyingDetail'),
        name: 'groupBuyingDetail',
        hidden: true,
        meta: {
          title: 'groupBuyingDetail',
          noCache: true,
          activeNames: ['activityGroupBuyOrder']
        }
      },
      {
        path: 'serve-list',
        component: () => import('@/views/order/serverList'),
        name: 'serverList',
        meta: { title: 'serverList' }
      },
      {
        path: 'coupon-order-list',
        component: () => import('@/views/order/couponOrderList'),
        name: 'couponOrderList',
        meta: { title: 'couponOrderList' }
      },
      {
        path: 'supplement-list',
        name: 'supplementList',
        component: () => import('@/views/order/supplement/supplementList'),
        meta: { title: 'supplementList' }
      },
      {
        path: 'verification-list',
        component: () => import('@/views/order/verificationList'),
        name: 'verify_record_list',
        meta: { title: 'verificationList' },
      },
      {
        path: 'supplement-detail/:sn',
        name: 'supplementDetail',
        component: () => import('@/views/order/supplement/supplementDetail'),
        meta: { title: 'supplementDetail', activeNames: ['supplementList'] },
        hidden: true
      },
      {
        path: 'coupon-order-detail/:id',
        component: () => import('@/views/order/couponOrderDetail'),
        name: 'couponOrderDetail',
        meta: {
          title: 'couponOrderDetail',
          activeNames: ['couponOrderList'],
        },
        hidden: true
      },
      // 维权订单详情
      {
        path: 'refund-detail/:sn',
        component: () => import('@/views/order/refundList/refundDetail'),
        name: 'refundDetail',
        meta: {
          title: 'refundDetail',
          activeNames: ['refundList'],
        },
        hidden: true
      },
      // 订单详情
      {
        path: ':route/detail/:sn',
        component: () => import('@/views/order/orderList/orderDetail'),
        name: 'orderDetail',
        hidden: true,
        meta: {
          title: 'orderDetail',
          noCache: true,
          activeNames: ['orderList'],
        }
      },

    ]
  },
  // 财务管理
  {
    path: '/finance',
    component: Layout,
    redirect: '/finance/overview',
    name: 'finance',
    meta: {
      title: 'finance',
      icon: 'finance'
    },
    children: [
      {
        path: 'overview',
        component: () => import('@/views/finance/overview'),
        name: 'overview',
        meta: { title: 'overview' }
      },
      {
        path: 'enterprise-overview',
        component: () => import('@/views/finance/enterpriseOverview'),
        name: 'enterpriseOverview',
        meta: { title: 'enterpriseOverview' }
      },
      {
        path: 'shop-overview',
        component: () => import('@/views/finance/shopOverview'),
        name: 'shopOverview',
        meta: { title: 'shopOverview' }
      },
      {
        path: 'summary',
        component: () => import('@/views/finance/summary'),
        name: 'summary',
        meta: { title: 'summary' }
      },
      {
        path: 'finance-summary',
        component: () => import('@/views/finance/summary'),
        name: 'financeSummary',
        meta: { title: 'financeSummary' }
      },
      {
        path: 'detailed',
        component: () => import('@/views/finance/detailed'),
        name: 'detailed',
        meta: { title: 'detailed' }
      },
      {
        path: 'finance-detailed',
        component: () => import('@/views/finance/financeDetailed'),
        name: 'financeDetailed',
        meta: { title: 'financeDetailed' }
      },
      {
        path: 'deal-detailed',
        component: () => import('@/views/finance/dealDetailed'),
        name: 'dealDetailed',
        meta: { title: 'dealDetailed' }
      },
      // 分销提现审核
      {
        path: "distribution-review",
        component: () => import("@/views/finance/distributionReview"),
        name: "distributionCashWithdrawApprove",
        meta: { title: "distributionCashWithdrawApprove" },
      },
      {// 账单列表
        path: 'bill-list',
        component: () => import('@/views/finance/billList'),
        name: 'member_bill_list',
        meta: { title: 'billList' }
      },
      {// 账单列表详情列表
        path: 'billListDetail',
        component: () => import('@/views/finance/billListDetail'),
        name: 'billListDetail',
        meta: {
          title: 'billListDetail',
          noCache: true,
          activeNames: ['member_bill_list']
        },
        hidden: true
      },
      {
        path: 'cancel-after-verification',
        component: () => import('@/views/finance/writeOffOpenCard'),
        name: 'writeOffOpenCard',
        meta: { title: 'writeOffOpenCard' }
      },
      {
        path: 'cancel-after-verification-detailed',
        component: () => import('@/views/finance/writeOffOpenCardDetailed'),
        name: 'writeOffOpenCardDetailed',
        meta: {
          title: 'writeOffOpenCardDetailed',
          activeNames: ['writeOffOpenCard'],
        },
        hidden: true
      },
      {
        path: 'site-finance',
        component: () => import('@/views/finance/siteFinance'),
        name: 'siteFinance',
        meta: { title: 'siteFinance' }
      },
      {
        path: 'account',
        component: () => import('@/views/finance/account'),
        name: 'financeAccount',
        meta: { title: 'financeAccount' }
      },
      {
        path: 'accountg',
        component: () => import('@/views/finance/account'),
        name: 'financeAccountg',
        meta: { title: 'financeAccountg' }
      },
      {
        path: 'accounta',
        component: () => import('@/views/finance/account'),
        name: 'financeAccounta',
        meta: { title: 'financeAccounta' }
      },
      {
        path: 'money',
        component: () => import('@/views/finance/money'),
        name: 'guaranteeMoney',
        meta: { title: 'guaranteeMoney' }
      },
      {
        path: 'Invoicereview',
        component: () => import('@/views/finance/Invoicereview2'),
        name: 'Invoicereview',
        meta: { title: 'Invoicereview' }
      },

      {
        path: 'Invoicemanger',
        component: () => import('@/views/finance/Invoicemanger'),
        name: 'Invoicemanger',
        meta: { title: 'Invoicemanger' },
      },
      {
        path: 'Invoicemanger/requestInvoice',
        component: () => import('@/views/finance/requestInvoice'),
        name: 'requestInvoice',
        hidden: true,
        meta: {
          title: 'requestInvoice',
          activeNames: ['Invoicemanger']
        },
      },
      {
        path: 'Invoicemanger/administer',
        component: () => import('@/views/finance/administer'),
        name: 'administer',
        hidden: true,
        meta: {
          title: 'administer',
          activeNames: ['Invoicemanger']
        },
      },
      {
        path: 'Invoicemanger/Invoicemangerdetail',
        component: () => import('@/views/finance/Invoicemangerdetail'),
        name: 'Invoicemangerdetail',
        hidden: true,
        meta: {
          title: 'Invoicemangerdetail',
          activeNames: ['Invoicemanger']
        },
      },
      {
        path: 'Invoicemanger/Invoicemangerjilu',
        component: () => import('@/views/finance/Invoicemangerjilu'),
        name: 'Invoicemangerjilu',
        hidden: true,
        meta: {
          title: 'Invoicemangerjilu',
          activeNames: ['Invoicemanger']
        },
      },
      {
        path: 'Invoicemanger/Invoicemangerjiludetail',
        component: () => import('@/views/finance/Invoicemangerjiludetail'),
        name: 'Invoicemangerjiludetail',
        hidden: true,
        meta: {
          title: 'Invoicemangerjiludetail',
          activeNames: ['Invoicemanger']
        },
      },
      {
        path: 'Invoicemangerg',
        component: () => import('@/views/finance/Invoicemanger'),
        name: 'Invoicemangerg',
        meta: { title: 'Invoicemangerg' },
      },
      {
        path: 'messageGai',
        component: () => import('@/views/finance/messageGai'),
        name: 'messageGai',
        meta: { title: 'messageGai' }
      },
      {
        path: 'messageManage',
        component: () => import('@/views/finance/messageManage'),
        name: 'messageManage',
        meta: { title: 'messageManage' },
        children: [{
          path: 'sqmsg',
          component: () => import('@/views/finance/sqmsg'),
          name: 'sqmsg',
          meta: { title: 'sqmsg' }
        }]
      },
      {
        path: 'messagePei',
        component: () => import('@/views/finance/messagePei'),
        name: 'messagePei',
        meta: { title: 'messagePei' }
      },
      {
        path: ':route/detail/:sn',
        component: () => import('@/views/finance/detail'),
        name: 'detail',
        hidden: true,
        meta: {
          title: 'detail',
          activeNames: ['financeDetailed']
        }
      },
      {
        path: ':route/orderDetail/:sn',
        component: () => import('@/views/order/orderList/orderDetail'),
        name: 'orderDetail',
        hidden: true,
        meta: {
          title: 'orderDetail',
          activeNames: ['siteFinance', 'dealDetailed', 'detailed'],
        }
      },
      {
        path: 'lookInvoicereview/:id?',
        component: () => import('@/views/finance/lookInvoicereview'),
        name: 'lookInvoicereview',
        hidden: true,
        meta: {
          title: 'lookInvoicereview',
          activeNames: ['Invoicereview']
        }
      },

    ]
  },
  // 统计
  {
    path: '/statistics',
    component: Layout,
    redirect: '/statistics/generality-overview',
    name: 'statistics',
    meta: {
      title: 'statistics',
      icon: 'data'
    },
    children: [
      {
        path: 'shop-flow',
        component: () => import('@/views/statistics/analysis/shopFlow'),
        name: 'shopFlow',
        meta: { title: 'shopFlow' }
      },
      {
        path: 'shop-user',
        component: () => import('@/views/statistics/analysis/shopUser'),
        name: 'shopUser',
        meta: { title: 'shopUser' }
      },
      {
        path: 'shop-active',
        component: () => import('@/views/statistics/analysis/shopActive'),
        name: 'shopActive',
        meta: { title: 'shopActive' }
      },
      {
        path: 'shop-data-today',
        component: () => import('@/views/statistics/analysis/shopDataToday'),
        name: 'shopDataToday',
        meta: { title: 'shopDataToday' }
      },
      {
        path: 'shop-data-daily',
        component: () => import('@/views/statistics/analysis/shopDataDaily'),
        name: 'shopDataDaily',
        meta: { title: 'shopDataDaily' }
      },
      {
        path: 'data-today',
        component: () => import('@/views/statistics/analysis/dataToday'),
        name: 'dataToday',
        meta: { title: 'dataToday' }
      },
      {
        path: 'deal-revenue',
        component: () => import('@/views/statistics/analysis/dealRevenue'),
        name: 'dealRevenue',
        meta: { title: 'dealRevenue' }
      },
      {
        path: 'revenue',
        component: () => import('@/views/statistics/analysis/revenue'),
        name: 'revenue',
        meta: { title: 'revenue' }
      },
      {
        path: 'order',
        component: () => import('@/views/statistics/analysis/order'),
        name: 'orderStatistics',
        meta: { title: 'orderStatistics' }
      },
      {
        path: 'goods',
        component: () => import('@/views/statistics/analysis/goods'),
        name: 'goodsStatistics',
        meta: { title: 'goodsStatistics' }
      }
    ]
  },
  // 商城管理
  {
    path: '/application',
    component: Layout,
    redirect: '/application/shop-management',
    name: 'application',
    meta: {
      title: 'application',
      icon: 'app'
    },
    children: [
      {
        path: 'shop-management',
        component: () => import('@/pages/application/shop-management/enterprise/shop-list'),
        name: 'shopManagement',
        hidden: true,
        force: true,
        meta: { title: 'shopManagement' }
      },
      {
        path: 'mallManagementEdit',
        component: () => import('@/views/application/mallManagementEdit'),
        name: 'mallManagementEdit',
        hidden: true,
        meta: { title: 'mallManagementEdit' }
      },
      {
        path: 'combo-card-shop-edit',
        component: () => import('@/views/application/combo-card-shop-edit.vue'),
        name: 'comboCardShopEdit',
        hidden: true,
        meta: { title: 'comboCardShopEdit' }
      },
      // 充值卡
      {
        path: 'czkadd',
        component: () => import('@/views/application/czkMeal/czkMealAdd'),
        name: 'czkMealAdd',
        hidden: true,
        meta: { title: 'czkMealAdd' }
      },
      {
        path: 'czklist',
        component: () => import('@/views/application/czklist'),
        name: 'czkMealList',
        hidden: true,
        meta: { title: 'czkMealList' }
      },
      {
        path: 'exchangeCode1',
        component: () => import('@/views/application/dhm'),
        name: 'exchangeCodelist1',
        hidden: true,
        meta: { title: 'exchangeCodelist1' }
      },
      {
        path: 'add',
        component: () => import('@/views/application/setMeal/setMealAdd'),
        name: 'setMealAdd',
        meta: { title: 'setMealAdd' },
        hidden: true
      },
      {
        path: 'grant',
        component: () => import('@/views/application/grant'),
        name: 'grant',
        meta: { title: 'grant' },
        hidden: true
      },
      {
        path: 'my-grant',
        component: () => import('@/views/application/myGrant'),
        name: 'myGrant',
        meta: { title: 'myGrant' },
        hidden: true
      },
      {
        path: 'grant-use',
        component: () => import('@/views/application/grantUse'),
        name: 'grantUse',
        meta: { title: 'grantUse' },
        hidden: true
      },
      {
        path: 'grant-detail/:id?',
        component: () => import('@/views/application/grantDetail'),
        name: 'grantDetail',
        meta: { title: 'grantDetail' },
        hidden: true
      },
      {
        path: 'grant-details/:id?',
        component: () => import('@/views/application/grantDetails'),
        name: 'grantDetails',
        meta: { title: 'grantDetails' },
        hidden: true
      }
    ]
  },
  // 设置
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting/person',
    name: 'setting',
    meta: {
      title: 'setting',
      icon: 'settings'
    },
    children: [
      {
        path: 'safeSetting',
        component: () => import('@/views/setting/safeSetting'),
        name: 'safeSetting',
        meta: { title: 'safeSetting' }
      },
      {
        path: 'shop-transportg',
        component: () => import('@/views/setting/transportg'),
        name: 'transportg',
        meta: { title: 'transportg' }
      },
      // 分销设置
      {
        path: 'distributions-settings',
        component: () => import('@/views/setting/distributionSettings'),
        name: 'goodsDistributionRateSettings',
        meta: { title: 'goodsDistributionRateSettings' }
      },
      {
        path: 'shop-mesg',
        component: () => import('@/views/setting/shopMesg'),
        name: 'shopMesg',
        meta: { title: 'shopMesg' }
      },
      {
        path: 'shop-agree',
        component: () => import('@/views/setting/agreeIn'),
        name: 'agreeIn',
        meta: { title: 'agreeIn' }
      },
      {
        path: 'shareSetting',
        component: () => import('@/views/setting/shareSetting'),
        name: 'shareSetting',
        meta: { title: 'shareSetting' }
      },
      {
        path: 'shop-person',
        component: () => import('@/views/setting/person'),
        name: 'shopPerson',
        meta: { title: 'shopPerson' }
      },
      {
        path: 'shop-identify',
        component: () => import('@/views/setting/identify'),
        name: 'shopIdentify',
        meta: { title: 'shopIdentify' }
      },

      {
        path: 'shop-payment',
        component: () => import('@/views/setting/payment'),
        name: 'shopPayment',
        meta: { title: 'shopPayment' }
      },
      {
        path: 'shop-setting',
        component: () => import('@/views/setting/shopSettings'),
        name: 'shopSettings',
        meta: { title: 'shopSettings' }
      },
      {
        path: 'goods-warning',
        component: () => import('@/views/setting/goodsWarning'),
        name: 'goodsWarning',
        meta: { title: 'goodsWarning' }
      },
      {// 调查问卷
        path: "questionnaire",
        component: () => import("@/views/setting/questionnaire"),
        name: "questionnaire",
        meta: { title: "questionnaire" },
      },
      {// 问题反馈记录
        path: "problemFeedback",
        component: () => import("@/views/setting/problemFeedback"),
        name: "problem_feedback",
        meta: { title: "problemFeedback" },
      },
      {// 自提点管理
        path: "pickupPoint",
        component: () => import("@/views/setting/pickupPoint"),
        name: "pickup_station_manage",
        meta: { title: "pickupPoint" },
      },
      {// 物流公司
        path: 'shop-companyList',
        component: () => import('@/views/setting/companyList'),
        name: 'shopCompanyList',
        meta: { title: 'shopCompanyList' }
      },
      {
        path: 'shop-address',
        component: () => import('@/views/setting/address'),
        name: 'shopAddress',
        meta: { title: 'shopAddress' }
      },
      // {
      //   path: 'shop-addressa',
      //   component: () => import('@/views/setting/address'),
      //   name: 'shopAddressa',
      //   meta: { title: 'shopAddressa' }
      // },
      {
        path: 'shop-companyLista',
        component: () => import('@/views/setting/companyList'),
        name: 'logistics',
        meta: { title: 'logistics' }
      },
      {
        path: 'grade-application',
        component: () => import('@/views/setting/gradeApplication'),
        name: 'gradeApplication',
        meta: { title: 'gradeApplication' },
        hidden: true
      },
      {// 调查问卷详情列表
        path: "questionnaireDetail",
        component: () => import("@/views/setting/questionnaireDetail"),
        hidden: true,
        name: "questionnaireDetail",
        meta: {
          title: "questionnaireDetail",
          activeNames: ['questionnaire']
        },
      },
    ]
  },

  // 工具模块
  {
    path: '/tools',
    component: Layout,
    redirect: '/tools/promotion-manage',
    name: 'tools',
    meta: {
      title: 'tools',
      icon: 'tools'
    },
    children: [
    /**
           * 储值卡列表
           */
      {
        path: 'cash-coupon-list',
        component: () => import('@/views/tools/promotion-manage/cash-coupon-list'),
        name: 'cashCouponList',
        meta: {
          title: 'cashCouponList'
        }
      },
      {
        path: '/tools/promotion-manage', // 促销管理
        component: () => import('@/views/tools/promotion-manage/index.vue'),
        redirect: '/tools/promotion-manage/coupon-info',
        name: 'promotionManage',
        meta: {
          title: 'promotionManage',
        },
        children: [
          /**
           * 商家端
           * coupon 优惠券
           * cashCoupon 储值卡 原需求中的代金券
           * 企业端
           * ------另外企业端有个充值卡
           */
          /**
           * 优惠券列表
           */
          {
            path: 'coupon-list',
            component: () => import('@/views/tools/promotion-manage/coupon-list'),
            name: 'couponList',
            meta: {
              title: 'couponList'
            }
          },
          /**
           * 拼团活动列表
           */
          {
            path: 'group-buying-ctivity',
            component: () => import('@/views/tools/promotion-manage/group-buying-ctivity'),
            name: 'activityGroupBuy',
            meta: {
              title: 'groupBuyingCtivity'
            }
          },
          /**
           * 拼团活动列表-管理
           */
          {
            path: 'group-buying-goods',
            component: () => import('@/views/tools/promotion-manage/group-buying-goods'),
            name: 'groupBuyingGoods',
            hidden: true,
            meta: {
              title: 'groupBuyingGoods',
              activeNames: ['groupBuyingCtivity']
            }
          },
          /**
           * 优惠券列表-添加编辑
           */
          {
            path: 'coupon-edit',
            component: () => import('@/views/tools/promotion-manage/card-edit'),
            name: 'couponEdit',
            hidden: true,
            meta: {
              title: 'couponEdit',
              activeNames: ['couponList']
            }
          },

          {
            path: 'cash-coupon-edit',
            component: () => import('@/views/tools/promotion-manage/card-edit'),
            name: 'cashCouponEdit',
            hidden: true,
            meta: {
              title: 'cashCouponEdit',
              activeNames: ['cashCouponList']
            }
          },
          {
            path: 'exchangeCode',
            hidden: true,
            component: () => import('@/views/application/dhm'),
            name: 'exchangeCodelist',
            meta: {
              title: 'exchangeCodelist',
              activeNames: ['couponList']
            }
          },
          /**
           * 首件优惠(针对中石油固有shop_id商城)
           */
          {
            path: 'firstPieceList',
            component: () => import('@/views/tools/promotion-manage/first_piece_list'),
            name: 'first_piece_list',
            meta: {
              title: 'first_piece_list'
            }
          },

          /**
           * 首件优惠添加编辑(针对中石油固有shop_id商城)
           */
          {
            path: 'firstPieceEdit',
            component: () => import('@/views/tools/promotion-manage/firstPieceEdit'),
            name: 'firstPieceEdit',
            hidden: true,
            meta: {
              title: 'firstPieceEdit',
              activeNames: ['first_piece_list'],
            },
          },
        ]
      },
      {
        path: '/tools/sale-manage',
        component: () => import('@/views/tools/sale-manage/'),
        redirect: '/tools/sale-manage/sales-list',
        name: 'saleManage',
        meta: {
          title: 'saleManage'
        },
        children: [
          {
            path: 'sales-list',
            component: () => import('@/views/tools/sale-manage/sales-list'),
            name: 'salesList',
            meta: {
              title: 'salesList'
            }
          },
          {
            path: 'sales-edit',
            component: () => import('@/views/tools/sale-manage/sales-edit'),
            name: 'salesEdit',
            hidden: true,
            meta: {
              title: 'salesEdit'
            }
          },
          {
            path: 'programmeList',
            component: () => import('@/views/shopGoods/programmeList'),
            name: 'programmeList',
            meta: { title: 'programmeList' }
          },
          {
            path: 'programmeRecord',
            component: () => import('@/views/shopGoods/programmeRecord'),
            name: 'programmeRecord',
            meta: { title: 'programmeRecord' }
          },
          {
            path: 'programmeDetail/:id',
            component: () => import('@/views/shopGoods/programmeDetail'),
            name: 'programmeDetail',
            hidden: true,
            meta: { title: 'programmeDetail' }
          },
        ]
      },
      // 销售开卡
      {
        path: 'sale-card-open-management',
        name: 'saleCardOpenManagement',
        component: () => import('@/views/tools/sale-card-open/index'),
        meta: { title: `saleCardOpenManagement` }
      },
      {
        path: 'card-no-search',
        name: 'cardSearch',
        component: () => import('@/views/tools/sale-card-open/cardNoSearch')
      },
      // 销售订单记录
      {
        path: 'add-sale-order-record',
        name: 'addSaleOrderRecord',
        component: () => import('@/views/tools/sale-card-open/modules/addSaleOrderRecord'),
        meta: {
          title: `addSaleOrderRecord`,
          noCache: true,
          activeNames: ['saleCardOpenManagement']
        },
        hidden: true
      },
      {
        path: 'sale-order-detail',
        name: "saleOrderDetail",
        component: () => import('@/views/tools/sale-card-open/modules/saleOrderDetail'),
        meta: {
          activeNames: ['saleCardOpenManagement']
        },
        hidden: true
      },
      {
        path: 'sale-refund-detail',
        name: "saleRefundDetail",
        component: () => import('@/views/tools/sale-card-open/modules/saleRefundDetail'),
        meta: {
          activeNames: ['saleCardOpenManagement']
        },
        hidden: true
      },
      {
        path: 'add-sale-refund-record',
        name: 'addSaleRefundRecord',
        component: () => import('@/views/tools/sale-card-open/modules/addSaleRefundRecord'),
        meta: {
          title: `addSaleRefundRecord`,
          noCache: true,
          activeNames: ['saleCardOpenManagement']
        },
        hidden: true
      },
      // 分享文案管理
      {
        path: `share-video-list`,
        component: () => import(`@/views/tools/share-video/share-video-list`),
        name: `shareVideoList`,
        meta: { title: `shareVideoList` }
      },
      {
        path: `share-video-edit/:id?`,
        component: () => import(`@/views/tools/share-video/share-video-edit`),
        name: `shareVideoEdit`,
        meta: {
          title: `shareVideoEdit`,
          noCache: true,
          activeNames: ['shareVideoList']
        },
        hidden: true
      },
    ]
  },

  // 店员管理
  {
    path: '/shop-auth',
    component: Layout,
    redirect: '/shop-auth/shop-assistant',
    name: 'shopAuth',
    meta: {
      title: 'shopAuth',
      icon: 'clerk'
    },
    children: [
      {
        path: 'shop-assistant',
        component: () => import('@/views/shop-auth/shopAssistant'),
        name: 'shopAssistant',
        meta: { title: 'shopAssistant' }
      },
      {
        path: 'role-manage',
        component: () => import('@/views/shop-auth/roleManage'),
        name: 'roleManage',
        meta: { title: 'roleManage' }
      },
      {
        path: 'role-permission/:id(\\d+)',
        component: () => import('@/views/shop-auth/rolePermission'),
        name: 'rolePermission',
        hidden: true,
        meta: {
          title: 'rolePermission',
          noCache: true,
          activeNames: ['roleManage']
        }
      }
    ]
  },

  // 权限
  {
    path: '/shop-auth',
    component: Layout,
    redirect: '/shop-auth/shop-assistantq',
    name: 'shopAuthq',
    meta: {
      title: 'shopAuthq',
      icon: 'jurisdiction'
    },
    children: [
      {
        path: 'shop-assistantq',
        component: () => import('@/views/shop-auth/shopAssistantq'),
        name: 'shopAssistantq',
        meta: { title: 'shopAssistantq' }
      },
      {
        path: 'role-manage',
        component: () => import('@/views/shop-auth/roleManage'),
        name: 'roleManage',
        meta: { title: 'roleManage' }
      },
      {
        path: 'role-permission/:id(\\d+)',
        component: () => import('@/views/shop-auth/rolePermission'),
        name: 'rolePermission',
        hidden: true,
        meta: {
          title: 'rolePermission',
          noCache: true,
          activeNames: ['roleManage']
        }
      }
    ]

  },

  // 通知
  {
    path: '/info',
    component: Layout,
    redirect: '/info/infoList',
    name: 'info',
    meta: {
      title: 'info',
      icon: 'notify'
    },
    children: [
      {
        path: 'infoList',
        component: () => import('@/views/info/infoList'),
        name: 'infoList',
        meta: { title: 'infoList' }
      }
    ]
  },
  // ...comboCardShopRouterMap,
  {
    path: '*',
    hidden: true,
    redirect: '/404',
  },
]

// export const comboCardShopRouterMapTemp = [...comboCardShopRouterMap];

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
});
