/**
 * Created by Andste on 2018/5/9.
 */
import Vue from 'vue';
import {Foundation} from '@/../ui-utils';
import {api, domain} from '@/../ui-domain';
import * as XLSX from 'xlsx';
import * as API_Login from '@/api/login';
import store from '../store';
import * as API_Order from "@/api/order";
import {asyncRouterMap} from "@/router";

let isLoadedLoginType = false;
let MixinIsFormEnterprise = false;

const MixinOrderStatusOptions = [
  [
    {
      value: '',
      label: '全部'
    },
    {
      value: 'CONFIRM',
      label: '待付款'
    },
    {
      value: 'PAID_OFF',
      label: '待发货'
    },
    {
      value: 'SHIPPED',
      label: '待收货'
    },
    {
      value: 'COMPLETE',
      label: '已完成'
    },
    {
      value: 'CANCELLED',
      label: '已取消'
    },
    {
      value: 'PAID_OFF_HANGUP',
      label: '待发货挂起'
    }
  ],
  [
    {
      value: '',
      label: '全部'
    },
    {
      value: 'WAIT_PAY',
      label: '待付款'
    },
    {
      value: 'WAIT_SHIP',
      label: '待发货'
    },
    {
      value: 'PICKING',
      label: '拣货中'
    },
    {
      value: 'WAIT_ROG',
      label: '待收货'
    },
    {
      value: 'COMPLETE',
      label: '已完成'
    },
    {
      value: 'CANCELLED',
      label: '已取消'
    },
  ],
  [
    {
      value: '',
      label: '全部'
    },
    {
      value: 'WAIT_PAY',
      label: '待付款'
    },
    {
      value: 'SHIPPED',
      label: '待收货'
    },
    {
      value: 'COMPLETE',
      label: '已完成'
    },
    {
      value: 'CANCELLED',
      label: '已取消'
    },
  ],
  [
    {
      value: '',
      label: '全部'
    },
    {
      value: 'WAIT_SHIP',
      label: '待发货'
    },
    {
      value: 'PICKING',
      label: '拣货中'
    },
    {
      value: 'WAIT_ROG',
      label: '待收货'
    },
    {
      value: 'COMPLETE',
      label: '已完成'
    },
    {
      value: 'CANCELLED',
      label: '已取消'
    }
  ],
  [
    {
      value: '',
      label: '全部'
    },
    {
      value: 'PAID_OFF',
      label: '待发货'
    },
    {
      value: 'SHIPPED',
      label: '待收货'
    },
    {
      value: 'COMPLETE',
      label: '已完成'
    },
    {
      value: 'CANCELLED',
      label: '已取消'
    }
  ],
];

export default {
  data() {
    const hostIncludeSome = (function(f){
      return function(...arr){
        return arr.some(i => f(i))
      }
    })(location.host.includes.bind(location.host))
    return {
      MixinEnv: hostIncludeSome('test','localhost','172.20') ? (hostIncludeSome('two','172.20') ? 'dev2' : 'dev1') : 'pro' ,
      MixinOrderStatusOptions,
      MixinIsFormEnterprise,
      MixinShopExtAuthList:{
        allAuth: {
          video: 'shareVideoList'
        },// 要做二次路由检查的所有路由，key为后台接口返回的字符串，value为前台记录的对应的路由名字。
        respAuth: {},// 接口返回的某个商城拥有的二次检查的路由
        toHiddenAuth: []// 将要隐藏的路由名字
      },
      MixinIsEnterprise: store.getters.shopInfo && store.getters.shopInfo.shop_type === 3,
      MixinIsSupplier: store.getters.shopInfo && store.getters.shopInfo.shop_type === 2,
      MixinIsSite: store.getters.shopInfo && store.getters.shopInfo.shop_type === 1,
      MixinPageSizes: [10, 20, 50, 100],
      MixinTableLayout: 'total, sizes, prev, pager, next, jumper',
      // 图片上传API
      MixinUploadApi: api.base + '/uploaders',
      // 地区API
      MixinRegionApi: api.base + '/regions/@id/children',
      // 买家端域名
      MixinBuyerDomain: domain.buyer_pc,
      MixinDefaultTimes: ['00:00:00', '23:59:59'],
      // 日期选择器快捷选项
      MixinPickerShortcuts: [{
        text: '最近一周',
        onClick(picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          picker.$emit('pick', [start, end])
        }
      }, {
        text: '最近一个月',
        onClick(picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          picker.$emit('pick', [start, end])
        }
      }, {
        text: '最近三个月',
        onClick(picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          picker.$emit('pick', [start, end])
        }
      }]
    }
  },
  computed: {
    isJDSupplier() {
      return this.$store.state.user.user.third_shop === 'jingdong';
    },
    /**
     * 缓存页面数组
     * @returns {default.computed.cachedViews|(function())|Array|*|getters.cachedViews}
     */
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    /**
     * 返回默认时间 + 5分钟
     * 用于日期时间选择器的默认时间
     * @returns {string}
     * @constructor
     */
    MixinDefaultTime() {
      const today = new Date()
      let hours = today.getHours()
      let minutes = today.getMinutes() + 5
      let seconds = today.getSeconds()
      if (hours < 10) hours = '0' + hours
      if (minutes < 10) minutes = '0' + minutes
      if (seconds < 10) seconds = '0' + seconds
      return hours + ':' + minutes + ':' + seconds
    }
  },
  mounted() {
    if (!isLoadedLoginType) {
      isLoadedLoginType = true;
      API_Login.getLoginType().then(resp => {
        // 0 代表企业登录 1 代表企业跳转商家
        if (resp && resp.parentLogin === 1) {
          MixinIsFormEnterprise = true;
          this.MixinIsFormEnterprise = true;
        }
      });
    }
  },
  methods: {
    /**
     * 视频模块新加的二次菜单权限检查
     * @returns
     */
    MixinSetShopExtAuthList() {
      let map = this.MixinShopExtAuthList.allAuth;
      return new Promise((resolve,reject)=>{
        API_Login.getShopExtAuthList().then(resp => {
          if (resp&&resp.length) {
            resp = resp.split(',')
            resp.forEach(i => map[i] && (this.MixinShopExtAuthList.respAuth[map[i]] = 1))
          }
          this.MixinShopExtAuthList.toHiddenAuth = Object.values(this.MixinShopExtAuthList.allAuth).filter(i => !this.MixinShopExtAuthList.respAuth[i]);// 筛选出在allAuth，但不在respAuth中的结果
          resolve(this.MixinShopExtAuthList)
        });
      })
    },
    /**
     * 编号方法
     * @param {*} index el-table当前索引列scope.$index
     * @param {*} params 包含page_no和page_size的列表参数
     * @returns
     */
    code(index, params) {
      return (params.page_no - 1) * params.page_size + ++index;
    },
    MixinGetOrderStatus(status) {
      switch (status) {
        case 'NOT_APPLY':
          return '未申请';
        case 'APPLY':
          return '已申请';
        case 'PASS':
          return '审核通过';
        case 'REFUSE':
          return '审核未通过';
        case 'EXPIRED':
          return '已失效或不允许申请售后';
        case 'REFUNDFAIL':
          return '退款失败';
        case 'COMPLETED':
          return '完成';
        case 'WAIT_SEND_BACK':
          return '待寄回';
        case 'SEND_BACK':
          return '买家寄回';
        case 'SELLER_SHIP':
          return '卖家发货';
        case 'REFUSE_SHIP':
          return '卖家拒绝发货';
        default:
          return '';
      }
    },
    /** 返回从企业跳转到商家的相关信息  return {"parentId":289,"parentLogin":1,"parentName":"二点零"}*/
    MixinParentEnterpriseInfo() {
      // 0 代表企业登录   1 代表企业跳转商家
      return API_Login.getLoginType();
    },
    /** 返回克隆后的对象 */
    MixinClone(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    /** 用于修改单条表格数据后，set到tableData中 */
    MixinSetTableData(tableData, idName, id, response) {
      const {data} = tableData
      const index = data.findIndex(item => item[idName] === id)
      Vue.set(data, index, response)
    },
    /** 用于判断表单是否为空 */
    MixinRequired(message, trigger) {
      // return { required: true, pattern: /^\S.*$/gi, message: message + '[不能空格开始]', trigger: trigger || 'blur' }
      return {
        required: true,
        pattern: /^\S.*$/gi,
        message: message ? message : message + '[不能空格开始]',
        trigger: trigger || 'blur'
      }
    },
    /** 格式化金钱 */
    MixinFormatPrice(row, column, cellValue, index) {
      if (typeof cellValue !== 'number') return ''
      return '￥' + Foundation.formatPrice(cellValue)
    },
    /** 格式化时间戳 */
    MixinUnixToDate(row, column, cellValue, index) {
      return Foundation.unixToDate(cellValue)
    },
    /**
     * 导出Excel
     * @param json   要导出的json数据
     * @param name   要导出的文件名
     * @param type   要导出的数据类型
     * @constructor
     */
    MixinExportJsonToExcel(json, name = 'data', type = 'application/octet-stream') {
      const wb = {
        SheetNames: [],
        Sheets: {},
        Props: {}
      }
      if (!Array.isArray(json)) json = [json]
      json.forEach(item => {
        wb.SheetNames.push(item.sheet_name)
        wb.Sheets[item.sheet_name] = XLSX.utils.json_to_sheet(item.sheet_values, item.sheet_options)
      })
      const wopts = {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
      }
      let blob = new Blob([s2ab(XLSX.write(wb, wopts))], {type})
      let link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${name}.xlsx`
      link.click();
      // 释放资源
      setTimeout(() => {
        URL.revokeObjectURL(link.href)
      }, 100)

      function s2ab(s) {
        if (typeof ArrayBuffer !== 'undefined') {
          const buf = new ArrayBuffer(s.length)
          const view = new Uint8Array(buf)
          for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
          return buf
        } else {
          const buf = new Array(s.length)
          for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF
          return buf
        }
      }
    },
    // 没有销售开卡权限处理
    handleNoPermission(asyncRouterMap) {
      this.$store.dispatch('GenerateRoutes', asyncRouterMap).then(() => {
        this.$router.replace({path: '/'}).catch(() => {})
      })
    },
    // 销售开卡极端情况，用户操作导致页面刷新的处理
    saleCardRefreshPage(funcName, arg) {
      API_Order.getShopExtAuth().then(res => {
        if (res.open === 'OPEN') {
          if (arg) {
            this[funcName](arg)
          } else {
            this[funcName]()
          }
        } else {
          this.$message.error('平台已关闭销售开卡功能，如有其他问题，请联系平台。');
          setTimeout(() => {
            this.handleNoPermission(asyncRouterMap);
          }, 2000)
        }
      })
    },
        		/**
		 * 导出Excel
		 * @param json   要导出的json数据
		 * @param name   要导出的文件名
		 * @param type   要导出的数据类型
		 * @constructor
		 */
		MixinExportJosnToExcel(json, name = 'data', type = 'application/octet-stream') {
			let wb = { SheetNames: [], Sheets: {}, Props: { }}
			if (!Array.isArray(json)) json = [json]
			json.forEach(item => {
				wb.SheetNames.push(item.sheet_name)
				wb.Sheets[item.sheet_name] = XLSX.utils.json_to_sheet(item.sheet_values, item.sheet_options)
			})
			const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' }
			let blob = new Blob([s2ab(XLSX.write(wb, wopts))], { type })
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = `${name}.xlsx`
			link.click()
			// 释放资源
			setTimeout(() => {
				URL.revokeObjectURL(link.href)
			}, 100)

			function s2ab(s) {
				if (typeof ArrayBuffer !== 'undefined') {
					const buf = new ArrayBuffer(s.length)
					const view = new Uint8Array(buf)
					for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
					return buf
				} else {
					const buf = new Array(s.length)
					for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF
					return buf
				}
			}
		}
  }
}
