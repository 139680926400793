
import { Component, Vue, Watch } from "vue-property-decorator";
import { $layout } from "@/layouts/services/layout.service";
import { turnUrl } from "@/utils";
import * as API_order from "@/api/order";
import * as API_login from '@/api/login'
// import Storage from "@/utils/storage";
import { getSellerShopExtAuth } from "@/api/account";
import checkVersion from "@/utils/check-version";
@Component({
  name: "app"
})
export default class App extends Vue {
  shutDown = false;
  @Watch("$route", { deep: true, immediate: true })
  async routechange(to, from) {
    if (to.name === 'shopGoodsList') {
      const force_exchange_goods_open = await API_order.getShopExtAuth().then(resStatus => {
        return resStatus.force_exchange_goods_open === "OPEN"
      });
      API_login.getAutoUpDownStatus().then(resStatus => {
        this.$store.getters.permission_routers.forEach(item => {
          if (item.path === "/shop-goods") {
            // 开启智能运营
            API_login.getLoginType().then(res => {
              if (res && res.parentLogin === 1) { // 企业跳站点
                if (resStatus.data || force_exchange_goods_open) {
                  let status = false;
                  item.children.forEach(value => {
                    if (value.path === "intel-operation") {
                      status = true;
                    }
                  });
                  // 智能运营不存在时添加
                  if (!status && this.shutDown) {
                    item.children.push({
                      path: "intel-operation",
                      component: () =>
                        import("@/views/shopGoods/intelOperation.vue"),
                      name: "intelOperation",
                      meta: {
                        title: "intelOperation"
                      }
                    });
                  } else if (!status && !this.shutDown && sessionStorage.getItem('MixinIsFormEnterprise') !== 'false') {
                    this.$router.go(0);
                  }
                } else {
                  // 关闭智能运营
                  item.children.forEach((value, index) => {
                    if (value.path === "intel-operation") {
                      this.shutDown = true;
                      item.children.splice(index, 1);
                    }
                  });
                }
              } else {
                // 关闭智能运营
                item.children.forEach((value, index) => {
                  if (value.path === "intel-operation") {
                    this.shutDown = true;
                    item.children.splice(index, 1);
                  }
                });
              }
            })
          }
        });
      });
    } else if (to.name === "userList") {
      const res = await getSellerShopExtAuth(this.$store.getters.shopInfo.shop_id)
      const user_recharge = res.user_recharge === 'OPEN'// 用户充值权限
      this.$store.getters.permission_routers.forEach((item) => {
        if (item.path === "/users") {
          // 开启用户充值
          if (user_recharge) {
            let status = false;
            item.children.forEach((value) => {
              if (value.path === "userschong") {
                status = true;
              }
            });
            // 用户充值不存在时添加
            if (!status && this.shutDown) {
              item.children.push({
                path: "userschong",
                component: () =>
                  import("@/views/users/userschong.vue"),
                name: "userschong",
                meta: { title: "userschong" },
              });
            } else if (
              !status &&
              !this.shutDown &&
              sessionStorage.getItem("MixinIsFormEnterprise") !== "false"
            ) {
              this.$router.go(0);
            }
          } else {
            // 关闭用户充值
            item.children.forEach((value, index) => {
              if (value.path === "userschong") {
                this.shutDown = true;
                item.children.splice(index, 1);
              }
            });
          }
        }
      });
    }
    const isCardSale = await API_order.getShopExtAuth().then(resStatus => {
      if (resStatus) return resStatus.open === "OPEN";
    });
    // this.PermissionAssignment(
    //   from,
    //   isCardSale,
    //   "order",
    //   "coupon-order-list"
    // );
    // this.PermissionAssignment(
    //   from,
    //   isCardSale,
    //   "finance",
    //   "cancel-after-verification"
    // );
  }

  // PermissionAssignment(from, flag, parentRoute, childrenRoute) {
  //   const routerArr = ["order", "finance"];
  //   // 大平台开关控制菜单路由入口
  //   if (from && routerArr.indexOf(from.path.split("/")[1]) !== -1) {
  //     // 总路由循环
  //     this.$store.getters.permission_routers.forEach(item => {
  //       if (item.path === "/" + parentRoute) {
  //         if (!flag) {
  //           // 关闭菜单
  //           item.children.forEach((value, index) => {
  //             if (value.path === childrenRoute) {
  //               item.children.splice(index, 1);
  //             }
  //           });
  //         }
  //       }
  //     });
  //   }
  // }
  mounted() {
    checkVersion()
    const { tokens, fr } = turnUrl(location.href);
    if (tokens) {
      sessionStorage.setItem("session", tokens);
      this.$store.dispatch("loginToken", { token: tokens });
    }
    if (fr) {
      localStorage.setItem('sshl__shop_fr', fr)
    }
    $layout.mode.subscribe(mode => {
      this.$el.parentElement!.className = mode;
    });
    // const user =
    //   Storage.getItem("seller_user") ||
    //   JSON.parse((sessionStorage as any).getItem("from_enter_seller_user"));
    // if (user) {
    //   API_login.getUserRolesPermissions(user.role_id).then(res => {
    //     if (!res.length) {
    //       if (this.$route.path !== "/no-sale-open") {
    //         this.$router.push({ path: "/no-sale-open" });
    //       }
    //     } else {
    //       if (this.$route.path === "/no-sale-open") {
    //         this.$router.push({ path: "/" });
    //       }
    //     }
    //   });
    // }
  }
}
