/**
 * 商品管理API
 */

import request from "@/utils/request";

/**
 * 修改限购数量时使用
 * @returns {Promise<any>}
 */
export function shopGoodsRestrict(shop_goods_id, num) {
  return request({
    url: `seller/shopGoods/restrict/${shop_goods_id}/${num}`,
    method: "put",
  });
}
/**
 * 企业端查询多规格商品是否全部下架
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsOffShelf(goods_id) {
  return request({
    url: `seller/shopGoods/check-shelve-in-same-spu-by-goods/${goods_id}`,
    method: "get",
    loading: false,
  });
}
/**
 * 企业删除商品放入回收站
 * @param params
 * @returns {Promise<any>}
 */
export function delShop(goods_ids, params) {
  return request({
    url: `seller/goods/${goods_ids}/recycle`,
    method: "put",
    data: params,
  });
}

export function cdelShop(goods_ids, params) {
  return request({
    url: `seller/goods/${goods_ids}`,
    method: "delete",
    data: params,
  });
}

/**
 * 下架全部无货商品
 * @param params
 * @returns {Promise<any>}
 */
export function underShops() {
  return request({
    url: "seller/shopGoods/noquantity/under",
    method: "put",
  });
}

/**
 * 商家挑选商品
 * @param params
 * @returns {Promise<any>}
 */
export function addGoods(goods_ids, params) {
  return request({
    url: `seller/shopGoods/shopSelect/${goods_ids}`,
    method: "put",
    data: params,
  });
}

/**
 * 查询自动上下架规则
 * @returns {Promise<any>}
 */
export function getAutoUpDown() {
  return request({
    url: "seller/statistics/intelligentoperation/getAutoUpDown",
    method: "get",
  });
}

/**
 * 今日智能运营数据统计
 * @returns {Promise<any>}
 */
export function statistics() {
  return request({
    url: "seller/statistics/intelligentoperation/statistics",
    method: "get",
  });
}

/**
 * 智能运营数据列表统计查询
 * @returns {Promise<any>}
 */
export function getOperationList(params) {
  return request({
    url: "seller/statistics/intelligentoperation/list",
    method: "get",
    params,
  });
}

/**
 * 保存更改自动上下架规则
 * @param params
 * @returns {Promise<any>}
 */
export function saveAutoUpDown(params, data) {
  return request({
    url: "seller/statistics/intelligentoperation/saveAutoUpDown",
    method: "post",
    params,
    data,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 企业分配商品给商家
 * @param params
 * @returns {Promise<any>}
 */
export function sendShop(params) {
  return request({
    url: "seller/shopGoods/selectGoods",
    method: "post",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 企业分配商品给只能套餐卡
 * @param params
 * @returns {Promise<any>}
 */
export function sendShopTh(params) {
  return request({
    url: "seller/shopGoods/selectGoodsTh",
    method: "post",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 企业端获取商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsListq(params) {
  return request({
    url: "/seller/goods/list-all",
    method: "get",
    params,
  });
}

/**
 * 获取商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsList(params) {
  return request({
    url: "seller/goods",
    method: "get",
    loading: true,
    params,
  });
}

function getMinValue(max_key, params) {}

/**
 * 获取店铺商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getShopGoodsList(params) {
  const keys = Object.keys(params);

  // for (const key of keys) {
  //   const isMin = key.includes("_min");
  //   const isMax = key.includes("_max");
  //   const isLow = key.includes("low_");
  //   const isHigh = key.includes("high_");

  //   const value = params[key];

  //   if (isMin || isMax || isLow || isHigh) {
  //     if (value === 0 || value === "") {
  //       delete params[key];
  //     }
  //   }
  // }
  let params2 = {
    ...params
  }
  if (params2.self_goods == 8) {//邮寄商品
    params2.self_goods = 7;
    params2.delivery_method = 1;
  } else if (params.self_goods == 7) {//自提商品
    params2.self_goods = 7;
    params2.delivery_method = 2;
  }
  console.log(params2);
  return request({
    url: "/seller/shopGoods",
    method: "get",
    loading: false,
    params:params2,
  });
}

/**
 * 选择供应商商品
 * @param params
 * @returns {Promise<any>}
 */
export function selectShopGoods(params) {
  return request({
    url: "/seller/shopGoods",
    method: "post",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 商品列表 删除商品  商家将商品放入回收站  下架的商品才能放入回收站
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getComboBindGoods(id) {
  return request({
    url: `seller/goods/comboGoods/bind/${id}`,
    method: "get",
  });
}

/**
 * 商品列表 删除商品  商家将商品放入回收站  下架的商品才能放入回收站
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function deleteGoods(ids, params) {
  const _params = {};
  return request({
    url: `seller/goods/${ids}/recycle`,
    method: "put",
    data: _params,
  });
}

/**
 * 商品列表 删除商品  商家将商品放入回收站  下架的商品才能放入回收站
 * @param ids
 * @param params
 * @returns {Promise<any>}  sunk
 */
export function deleteGoodsa(ids) {
  return request({
    url: `seller/shopGoods/${ids}`,
    method: "delete",
  });
}

/**
 * 查询库存商品数据  查询商品sku（规格）信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsStockList(ids, params) {
  return request({
    url: `seller/goods/${ids}/skus`,
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 保存库存商品
 * @param goods_id
 * @param params
 * @returns {Promise<any>}
 */
export function reserveStockGoods(goods_id, params) {
  return request({
    url: `seller/goods/${goods_id}/quantity`,
    method: "put",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 发布商品   查询商品参数，获取所选分类关联的参数信息
 * @param category_id
 * @returns {Promise<any>}
 */
export function getGoodsParams(category_id) {
  return request({
    url: `seller/goods/category/${category_id}/params`,
    method: "get",
    loading: false,
  });
}

/**
 * 编辑商品   查询商品参数，获取所选分类关联的参数信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getEditGoodsParams(category_id, goods_id) {
  return request({
    url: `seller/goods/category/${category_id}/${goods_id}/params`,
    method: "get",
    loading: false,
  });
}

/**
 * 草稿箱编辑 查询草稿箱商品参数
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsDraftParams(ids, params) {
  return request({
    url: `seller/goods/draft-goods/${ids}/params`,
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 查询商品品类信息  ids 为category_id 商城商品品类
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsCatrgory(ids, params) {
  return request({
    url: `seller/goods/${ids}/skus`,
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 查询单个商品信息 商品列表 编辑
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodData(ids, params) {
  return request({
    url: `seller/goods/${ids}`,
    method: "get",
    loading: false,
    params,
  });
}
export function getSelfGoodsData(ids, params) {
  return request({
    url: `seller/goods/selfGoods/${ids}`,
    method: "get",
    loading: false,
    params,
  });
}
/**
 * 查询单个商品信息 商品列表 编辑
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getShopGoodData(ids, params) {
  return request({
    url: `seller/shopGoods/${ids}`,
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 查询单个草稿箱商品信息 草稿箱商品列表 编辑
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodDraftData(ids, params) {
  return request({
    url: `seller/goods/draft-goods/${ids}`,
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 品牌列表 ids为categoryid  商品发布，获取所选分类关联的品牌信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsBrandList(ids, params) {
  return request({
    url: `seller/goods/category/${ids}/brands`,
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 运费模板列表
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getTplList(ids, params) {
  return request({
    url: `seller/shops/ship-templates`,
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 获取积分商品分类列表
 * @param id
 */
export function getExchangeCatsList(id) {
  return request({
    url: `seller/promotion/exchange-cats/${id}/children`,
    method: "get",
    loading: false,
  });
}

/**
 * 供应商上架商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function shelves(ids, params) {
  return request({
    url: `seller/goods/${ids}/shelves`,
    method: "put",
    data: params,
  });
}

/**
 * 商家下架商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function underGoods(ids, params) {
  return request({
    url: `seller/goods/${ids}/under`,
    method: "put",
    data: params,
  });
}

/**
 * 普通供应商批量下架
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function batchTakedown(ids, params) {
  return request({
    url: `seller/goods/${ids}/under`,
    method: "put",
    data: params,
  });
}

/**
 * 普通供应商批量上架
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function batchPutaway(ids, params) {
  return request({
    url: `seller/goods/${ids}/shelvesIds`,
    method: "put",
    data: params,
  });
}

/**
 * 商家上架商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function upperGoods(ids, params) {
  return request({
    url: `seller/goods/${ids}/upper`,
    method: "put",
    data: params,
  });
}

/**
 * 商家列表下架商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function changeStatus(ids, params) {
  return request({
    url: `/seller/shopGoods/${ids}/changeStatus`,
    method: "put",
    data: params,
  });
}

/**
 * 上架正常商品 商品相关API-添加商品
 * @param params
 * @returns {Promise<any>}
 */
export function aboveGoods(params) {
  return request({
    url: "seller/goods",
    method: "post",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}
/**
 * 上架正常商品 商品相关API-添加商品
 * @param params
 * @returns {Promise<any>}
 */
export function mailAboveGoods(params) {
  return request({
    // url: "seller/goods",
    url:'/seller/goods/addSpecGoods',
    method: "post",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}
/**
 * 批量上架商品
 * @param params
 * @returns {Promise<any>}
 */
export function upBatchGoods(ids, params) {
  return request({
    url: `seller/goods/${ids}/upBatch`,
    method: "put",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 批量下架商品
 * @param params
 * @returns {Promise<any>}
 */
export function underBatchGoods(ids, params) {
  return request({
    url: `seller/goods/${ids}/under`,
    method: "put",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 修改正常商品 编辑商品时用到
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function editGoods(id, params) {
  return request({
    url: `seller/goods/${id}`,
    method: "put",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}
/**
 * 修改多规格商品 编辑多规格商品时用到
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function updateSpecGoodseditGoods(id, params) {
  return request({
    url: `seller/goods/updateSpecGoods/${id}`,
    method: "put",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}
/**
 * 修改店铺商品价格
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function revisePrice(id, params) {
  return request({
    url: `/seller/shopGoods/${id}/revisePrice`,
    method: "put",
    data: params,
    // headers: { 'Content-Type': 'application/json' }
  });
}

/**
 * 商家修改商品价格
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function nrevisePrice(params) {
  return request({
    url: "seller/shopGoods/shopRevisePrice",
    method: "post",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 修改正常商品 编辑商品时用到
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function editShopGoods(id, params) {
  return request({
    url: `seller/shopGoods/${id}`,
    method: "put",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 正常商品 保存草稿 草稿商品相关API-添加商品
 * @param params
 * @returns {Promise<any>}
 */
export function saveDraft(params) {
  return request({
    url: `seller/goods/draft-goods`,
    method: "post",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 上架草稿箱商品
 * @param params
 * @returns {Promise<any>}
 */
export function aboveDraftGoods(ids, params) {
  return request({
    url: `seller/goods/draft-goods/${ids}/market`,
    method: "put",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

/** 草稿箱商品 保存草稿 即 修改草稿箱商品 */
export function editDraftGoods(id, params) {
  return request({
    url: `seller/goods/draft-goods/${id}`,
    method: "put",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * 查询草稿箱sku信息
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function draftSku(id, params) {
  return request({
    url: `seller/goods/draft-goods/${id}/skus`,
    method: "get",
    data: params,
  });
}

/**
 * 获取草稿箱商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getDraftGoodsList(params) {
  return request({
    url: "seller/goods/draft-goods",
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 删除草稿箱商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function deleteDraftGoods(ids, params) {
  const _params = {};
  return request({
    url: `seller/goods/draft-goods/${ids}`,
    method: "delete",
    data: _params,
  });
}

/**
 * 获取回收站商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getRecycleGoodsList(params) {
  return request({
    url: "seller/goods",
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 回收站 还原商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function RecycleReductionGoods(ids, params) {
  return request({
    url: `seller/goods/${ids}/revert`,
    method: "put",
    data: params,
  });
}

/**
 * 回收站 彻底删除商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function RecycleDeleteGoods(ids, params) {
  return request({
    url: `seller/goods/${ids}`,
    method: "delete",
    data: params,
  });
}

/**
 * 获取预警商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getWarningGoodsList(params) {
  return request({
    url: "seller/goods/warning",
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 查看预警商品库存信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getWarningGoodsStockList(ids, params) {
  return request({
    url: `seller/goods/${ids}/skus`,
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 获取分销设置
 */
export function getDistributionSet() {
  return request({
    url: "seller/distribution/setting",
    method: "get",
    loading: false,
  });
}

/**
 * 获取分销返利信息
 * @param id
 */
export function getDistributionInfo(id) {
  return request({
    url: `seller/distribution/goods/${id}`,
    method: "get",
    loading: false,
  });
}

/**
 * 保存分销返利
 * @param params
 */
export function setDistributionInfo(params) {
  return request({
    url: "seller/distribution/goods",
    method: "put",
    data: params,
  });
}

/**
 * 供应商获得商品信息变更记录
 * @param params
 */
export function getChangeList(params) {
  return request({
    url: "seller/goods/change/log",
    method: "get",
    params,
  });
}

/**
 * 京东获取列表
 * @param params
 */
export function getJDGoodsList(params) {
  return request({
    url: "/jd/goods/search",
    method: "post",
    params,
    loading: false,
  });
}

/**
 * 京东获取等级
 * @param params
 */
export function getJDGoodsCategoryLevelList(depth) {
  return request({
    // url: '/jd/goods/getCategorys',
    url: `jd/category/depth/${depth}`,
    method: "get",
    loading: true,
  });
}

/**
 * 京东获取等级
 * @param params
 */
export function getJDGoodsDetail(params) {
  return request({
    url: "/jd/goods/getGoodsDetail",
    method: "get",
    loading: true,
    params,
  });
}

/**
 * 京东接口-查询一级地址
 */
export function getJDProvince() {
  return request({
    url: "/jd/address/getProvince",
    method: "get",
    loading: true,
    switchToBase: true,
  });
}

/**
 * 京东接口-查询二级地址
 * @param params
 */
export function getJDCity(id) {
  return request({
    url: `/jd/address/getCity/${id}`,
    method: "get",
    loading: true,
    switchToBase: true,
  });
}

/**
 * 京东接口-查询三级地址
 * @param params
 */
export function getJDCounty(id) {
  return request({
    url: `/jd/address/getCounty/${id}`,
    method: "get",
    loading: true,
    switchToBase: true,
  });
}

/**
 * 京东接口-查询四级地址
 * @param params
 */
export function getJDTown(id) {
  return request({
    url: `/jd/address/getTown/${id}`,
    method: "get",
    loading: true,
    switchToBase: true,
  });
}

/**
 * 供应商挑选京东商品
 * @param params
 */
export function getJDchoseJdGoods(params) {
  return request({
    url: "/seller/goods/jd/choseJdGoods",
    method: "post",
    loading: false,
    params,
  });
}

/**
 * 商家判断是否为企业用户
 * @param params
 */
export function getLoginType() {
  return request({
    url: "/seller/shops/getLoginType",
    method: "get",
    loading: false,
  });
}

/**
 *
 */

export function getWaitReceiptOrderList(params) {
  // 订单列表
  return request({
    url: "seller/shops/shopReceipt/getWaitReceiptOrderList",
    method: "get",
    loading: false,
    params,
  });
}

export function getWaitReceiptOrderListaddHistory(data) {
  // 订单列表点击索取发票
  return request({
    url: "seller/shops/shopReceipt/addHistory",
    method: "put",
    loading: false,
    data,
  });
}

export function getShopReceipt(params) {
  // 服务列表
  return request({
    url: "seller/shops/shopReceipt",
    method: "get",
    loading: false,
    params,
  });
}

export function getHistoryList(params) {
  // 企业查询发票记录列表
  return request({
    url: "seller/shops/shopReceiptHistory/getHistoryList",
    method: "get",
    loading: false,
    params,
  });
}

export function getInfo(id) {
  // 查看发票记录详情
  return request({
    url: `seller/shops/shopReceiptHistory/getInfo/${id}`,
    method: "get",
    loading: false,
  });
}

export function getOrderList(params) {
  // 查看发票记录详情商品/服务
  return request({
    url: `seller/shops/shopReceiptHistory/getOrderList`,
    method: "get",
    loading: false,
    params,
  });
}

/**
 * 获取物流By Id
 */
export function getExpressByHistoryId(params) {
  return request({
    url: `seller/shops/shopReceiptHistory/getExpressListByHistoryId`,
    method: "get",
    params,
  });
}

export function getShopReceiptaddHistoryService(params) {
  // 服务列表点击索取发票
  return request({
    url: "seller/shops/shopReceipt/addHistoryService",
    method: "put",
    loading: false,
    params,
  });
}

export function getWaitOrderTotalPrice(params) {
  // 待开订单表总金额
  return request({
    url: "seller/shops/shopReceipt/getWaitOrderTotalPrice",
    method: "get",
    loading: false,
    params,
  });
}

export function getWaitServiceOrderTotalPrice(params) {
  // 待开服务表总金额
  return request({
    url: "seller/shops/shopReceipt/getWaitServiceOrderTotalPrice",
    method: "get",
    loading: false,
    params,
  });
}

export function getShopReceiptAddress() {
  // 查询平台发票收票地址列表
  return request({
    url: "seller/shops/shopReceiptAddress",
    method: "get",
    loading: false,
  });
}

export function getDefaultShopReceiptAddress() {
  // 查询平台发票收票默认地址列表
  return request({
    url: "seller/shops/shopReceiptAddress/getDefault",
    method: "get",
    loading: false,
  });
}

export function postShopReceiptAddress(data) {
  //添加发票收票地址列表
  return request({
    url: "seller/shops/shopReceiptAddress",
    method: "post",
    loading: false,
    data,
  });
}

export function delShopReceiptAddress(id) {
  //删除发票收票地址
  return request({
    url: `seller/shops/shopReceiptAddress/${id}`,
    method: "delete",
    loading: true,
  });
}

export function detailShopReceiptAddress(id) {
  //获取发票收票地址详情
  return request({
    url: `seller/shops/shopReceiptAddress/${id}`,
    method: "get",
    loading: false,
  });
}

export function editShopReceiptAddress(data) {
  //获取发票收票地址详情
  return request({
    url: `seller/shops/shopReceiptAddress/${data.id}`,
    method: "put",
    loading: false,
    data,
  });
}

export function defaultShopReceiptAddress(id) {
  //设置店铺默认发票收票地址
  return request({
    url: `seller/shops/shopReceiptAddress/default/${id}`,
    method: "put",
    loading: false,
  });
}

export function getInvoiceTitleDetail() {
  // 查询发票信息（抬头）
  return request({
    url: "seller/shops/shopReceipt/getDetail",
    method: "get",
    loading: false,
  });
}

export function postInvoiceTitleDetail(data) {
  // 查询发票信息（抬头）
  return request({
    url: "seller/shops/shopReceipt",
    method: "post",
    loading: false,
    data,
  });
}

export function addHistory() {
  // 添加商品订单发票信息
  return request({
    url: "seller/shops/shopReceipt/addHistory",
    method: "get",
    loading: false,
  });
}

export function getDetail() {
  // 查询发票信息
  return request({
    url: "seller/shops/shopReceipt/getDetail",
    method: "get",
    loading: false,
  });
}

export function getretentionMoney() {
  // 查询供应商质保金缴纳
  return request({
    url: "/seller/shopGoods/retentionMoney",
    method: "get",
    loading: false,
  });
}

// 导出商品列表
export function exportList(params) {
  return request({
    url: "/seller/goods/exportList",
    method: "get",
    params,
    loading: false,
  });
}

// 批量导入商品和库存
export function importInGoods(params) {
  return request({
    url: "/seller/goods/importInGoods",
    method: "post",
    data: params,
    loading: false,
    headers: { "Content-Type": "application/json" },
  });
}

// 检测是否存在改价任务
export function hasReviseTask() {
  return request({
    url: "/seller/shopGoods/shopProcess",
    method: "get",
    loading: false,
  });
}

// 2.0批量导出
export function exportNewList(params) {
  return request({
    url: "/seller/goods/exportList",
    method: "get",
    params,
    loading: true,
  });
}

// 2.0批量导入
export function importNewList(params) {
  return request({
    url: "/seller/goods/bulkImportGoods",
    method: "post",
    data: params,
    loading: true,
    headers: { "Content-Type": "application/json" },
  });
}

// 商品改价记录
export function goodsChangePrice(params) {
  return request({
    url: "seller/goods/shopGoodsPriceChangeLog/page",
    method: "post",
    data: params,
    loading: true,
  });
}

/**
 * 获取平台运费待开发票列表
 */
export function getPlatFormFreight(params) {
  return request({
    url: "/seller/shops/shopReceipt/getFreightWaitReceiptTradeList",
    method: "get",
    params,
  });
}

/**
 * 获取平台运费待开发票总金额
 */
export function getPlatFormFreightTotalPrice(params) {
  return request({
    url: "/seller/shops/shopReceipt/getWaitPlatformFreightTotalPrice",
    method: "get",
    params,
  });
}

/**
 * 索取平台发票
 */
export function addHistoryFreight(params) {
  return request({
    url: "/seller/shops/shopReceipt/addHistoryFreight",
    method: "put",
    data: params,
  });
}

/**
 * JD导入数据
 */
export function exportJdGoods(params) {
  return request({
    url: "/jd/goods/importJdGoods",
    method: "post",
    data:params.extGoodsIds,
    loading: true,
    headers: { "Content-Type": "application/json" },
  });
}

/**
 * JD数据导入状态
 */
export function exportJdGoodsState() {
  return request({
    url: "/jd/goods/getImportState",
    method: "post",
    loading: false,
  });
}

/**
 * JD导入单条查询
 */
export function getJdData(id){
  return request({
    url: `/jd/goods/getRecord/${id}`,
    method: "get",
  });
}

/**
 * JD导入单条查询
 */
export function getJdExportData(id){
  return request({
    url: `/jd/goods/getRecord/${id}`,
    method: "get",
  });
}

/**
 * JD导入记录列表
 */
export function getJdNotesList(params){
  return request({
    url:"/jd/goods/getImportRecordList",
    method: "get",
    params
  })
}

/**
 * 查询智能换货规则
 */
export function getGoodsCardRule() {
  return request({
    url: "/seller/statistics/intelligentoperation/getGoodsCardRule",
    method: "get"
  });
}

/**
 * 保存智能换货规则
 */
export function saveGoodsCardRule(params) {
  return request({
    url: "/seller/statistics/intelligentoperation/saveGoodsCardRule",
    headers: { "Content-Type": "application/json" },
    method: "post",
    data: params
  });
}

/**
 * 保存智能换货规则
 */
export function changeSaleStatus(goods_id,status) {
  return request({
    url: `/seller/shopGoods/${goods_id}/changeSaleStatus`,
    method: "PUT",
    params:{
      status
    }
  });
}

// 修改礼包智能换货开关状态Th
export function editAiExchangeTh(comboId,status) {
  return request({
    url: `/seller/shopCombo/editAiExchangeTh/${comboId}`,
    method: "POST",
    params:{
      status
    }
  });
}


// 获取店铺商品是否进行批量售卖
export function checkSoldSeparately() {
  return request({
    url: `/seller/shopGoods/checkSoldSeparately`,
    method: "GET",
    loading:false,
  });
}

// 批量设置售卖
export function changeGoodsSale(params,is_batch) {
  return request({
    url: `/seller/shopGoods/changeGoodsSale`,
    method: "POST",
    // headers: { "Content-Type": "application/json" },
    params,
    data:{
      is_batch
    }
  });
}

// 获取店铺礼包是否进行批量设置智能换货
export function checkGoodsAiExchange() {
  return request({
    url: `/seller/shopCombo/checkGoodsAiExchange`,
    method: "GET",
    loading:false,
  });
}

// 批量开关智能换货
export function changeGoodsAiExchange(params,is_batch) {
  return request({
    url: `/seller/shopCombo/changeGoodsAiExchange`,
    method: "POST",
    // headers: { "Content-Type": "application/json" },
    params,
    data:{
      is_batch
    }
  });
}
/**
 * 批量开关置换商品置顶
 */
export function changeThAutoReplaceReplaceableGoods(params,is_batch) {
  return request({
    url: `/seller/shopCombo/changeThAutoReplaceReplaceableGoods`,
    method: "POST",
    params,
    data:{
      is_batch
    }
  });
}
/**
 * 批量开关正常品替换功能
 */
export function changeThNormalShowAiReplaceButton(params,is_batch) {
  return request({
    url: `/seller/shopCombo/changeThNormalShowAiReplaceButton`,
    method: "POST",
    params,
    data:{
      is_batch
    }
  });
}
/**
 * 礼包导出PPT
 */
export function getImportRecordList(params){
  return request({
    url:"/seller/shopCombo/exportComboList",
    method: "get",
    params
  })
}
/**
 * 礼包导出PPT方案二
 */
export function newExportComboList(params){
  return request({
    url:"/seller/shopCombo/newExportComboList",
    method: "get",
    params
  })
}
/**
 * 查询是否是国联智诚企业
 */
export function getExportShopIds() {
  return request({
    url: `site-show/getExportShopIds`,
    method: 'get',
    switchToBase:true
  })
}

/**
 * 礼包导出EXCEL
 */
export function exportExcelComboList(data){
  return request({
    url:"/seller/shopCombo/exportExcelComboList",
    method: "post",
    headers: { "Content-Type": "application/json" },
    data:data
  })
}
/**
 * 获取单个商品的详情
 * @param {*} goods_id 
 * @returns 
 */
export function getGoodsDetail (goods_id) {
  return request({
    url:"/seller/goods/getGoodsDetail/" + goods_id,
    method: "get",
  })
}
/**
 * 批量导入商城自有商品
 * @param {*} 
 * @returns 
 */
export function bulkImportSelfGoods(params) {
  return request({
    url: "/seller/goods/bulkImportSelfGoods",
    method: "post",
    data: params,
    loading: true,
    headers: { "Content-Type": "application/json" },
  });
}
/**
 * 更新商城自有商品指定日期自提和截止上下单时间
 * @param {*}
 * @returns 
 */
export function batchSetPickUpTime (param,ids) {
  return request({
    url:`/seller/shopGoods/${ids}/batchSetPickUpTime`,
    method: "put",
    data:param
  })
}
/**
 * 查询改价记录 店铺 单个传goodsId 批量传0 
 * * @param goods_id
 */
export function getChangePriceLog(goods_id) {
	return request({
		url: `/seller/shopGoods/getShopChangePriceLog/${goods_id}`,
		method: 'get'
	})
}
/**
 * 查询企业下所有店铺套餐礼包列表
 */
export function getEnterpriseCombo(params) {
	return request({
		url: `/seller/enterpriseCombo`,
    method: 'get',
    params
	})
}
/**
 * 企业端挑选礼包到店铺
 */
export function selectGoodsCombo(data) {
	return request({
		url: `/seller/enterpriseCombo/selectGoodsCombo`,
    method: 'post',
    headers: { "Content-Type": "application/json" },
    data,
	})
}