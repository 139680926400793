import { asyncRouterMap, constantRouterMap } from '@/router'
import { getUserRolesPermissions, getAutoUpDownStatus, getLoginType } from '@/api/login'
import { getDomainByBasicInformation } from "@/api/account"
import Storage from '@/utils/storage'
import mixins from '@/utils/mixin'
import Vue from 'vue'
import store from '@/store'
import { getCurrentMainDomain } from "@/utils/domain";

function getAbleRouteName(routes = []) {
  let name = '';

  for (const route of routes) {
    let childName = '';

    if (route.children && route.children.length > 0) {
      childName = getAbleRouteName(route.children);
    } else {
      if (!route.hidden || route.force) {
        childName = route.name;
      }
    }

    if (childName && route.redirect) {
      route.redirect = {
        name: childName,
      };
    }

    if (!name) {
      name = childName;
    }
  }

  return name;
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      // 不同权限的用户路由不同，所以会出现有的用户没有默认根路由（''或'/'），此处手动设置一个根路由为用户拥有的权限路由的第一个
      if (routers[0].path !== '/' || routers[0].path !== '') {
        routers.unshift({
          path: '',
          hidden: true,
          redirect: {
            name: getAbleRouteName(routers),
          },
        });
      }

      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes({ commit }, params) {
      let originalRouterMap = [];
      if (!params) {
        originalRouterMap = asyncRouterMap
      } else {
        originalRouterMap = params
      }

      const user = Storage.getItem('seller_user') || JSON.parse(sessionStorage.getItem('from_enter_seller_user'));
      if (!user) return Promise.reject();

      if (location.host.indexOf('192.168') === -1 && location.host.indexOf('sshlqf') === -1) {
        const endUrl = getCurrentMainDomain() // 获取当前地址栏后缀主域名地址
        getDomainByBasicInformation(endUrl).then(res => {
          // 1商家  2供应商  3企业
          document.title = (() => {
            switch (user.shop_type) {
              case 1:
                return `${res.name}-商家管理系统`;
              case 2:
                return `${res.name}-供应商管理系统`;
              case 3:
                return `${res.name}-企业端管理系统`;
              case 6:
                return `${res.name}-双选商城管理系统`;
              case 8:
                return `${res.name}一件代发商城管理系统`;
              default: 
                return `${res.name}-套餐卡商城管理系统`;
            }
          })();
        })
      } else {
        // 1商家  2供应商  3企业
      document.title = (() => {
        switch (user.shop_type) {
          case 1:
            return '山水互联-商家管理系统';
          case 2:
            return '山水互联-供应商管理系统';
          case 3:
            return '山水互联-企业端管理系统';
          case 6:
            return '山水互联-双选商城管理系统';
          case 8:
            return '山水互联-一件代发商城管理系统';
          default:
            return '山水互联-套餐卡商城管理系统';
        }
      })();
      }

      let role_id = user.role_id;

      if (user.founder === 1) role_id = 0

      return new Promise((resolve, reject) => {
        // if (role_id === 0) {
        if (0) {// 打开权限筛选
          commit('SET_ROUTERS', asyncRouterMap)
          resolve()
        } else {
          getUserRolesPermissions(role_id).then(response => {
            // 判断是否有无账期供应商
            if (user.account_period === 0 && response.includes('financeAccounta')) {
              const index = response.findIndex(item => item === 'financeAccounta');
              response.splice(index, 1)
            }
            // 判断是否为第三方供应商
            if (user.third_shop && response.includes('goodPublish')) {
              const index = response.findIndex(item => item === 'goodPublish');
              response.splice(index, 1)
              // 如果是京东
              if (store.state.user.user.third_shop === "jingdong") {
                if (!response.includes("goodsImportList")) {
                  response.push("goodsImportList")
                }
              }
            }
            getLoginType().then(resp => {
              // 0 代表企业登录 1 代表企业跳转商家
              if (resp && resp.parentLogin === 1) {

                
                getAutoUpDownStatus().then(res => {
                  const bool = response.indexOf('intelligence') > -1 && res.data === 0
                  if (bool) response.splice(response.indexOf('intelligence'), 1)
                  // if (res.data === 0) {
                    //   response.splice(response.indexOf('intelOperation'), 1)
                    //   console.log('该功能已禁用')
                    // }
                  let accessedRouters = filterRoleRouter(originalRouterMap, response, user)
                  filterAuthRouter(accessedRouters).then(() => {
                    commit('SET_ROUTERS', accessedRouters);
                    resolve(response)
                  })
                })
              } else {
                let accessedRouters = filterRoleRouter(originalRouterMap, response, user)
                filterAuthRouter(accessedRouters).then(() => {
                  commit('SET_ROUTERS', accessedRouters);
                  resolve(response)
                })
              }
            });
          }).catch(reject)
        }
      })
    }
  }
}

/**
 * 递归筛选出有权限的路由
 * @param routers
 * @param names
 * @param user
 * @returns {Array}
 */
function filterRoleRouter(routers, names, user) {
  const _routers = []
  routers.forEach(item => {
    if (names.includes(item.name) || item.hidden) {
      if (item.children) {
        item.children = filterRoleRouter(item.children, names, user);
        if (item.children.length === 0) {
          delete item.redirect
        }

        if (user.shop_id === 66) {
          item.children.forEach(d => {
            if (d.name === "Invoicereview") {
              d["hidden"] = true
            }
          })
        }
      }
      _routers.push(item)
    }
  })
  return _routers
}

let toHiddenAuth = null;

function getHiddenAuth() {
  return new Promise(resolve => {
    if (!toHiddenAuth) {
      const MixinComponent = Vue.extend({ mixins: [mixins] });
      const component = new MixinComponent();
      component.MixinSetShopExtAuthList().then(resp => {
        toHiddenAuth = JSON.parse(JSON.stringify(resp.toHiddenAuth));
        resolve(toHiddenAuth);
      });
    } else {
      resolve(toHiddenAuth);
    }
  });
}

/**
 * 视频模块新加功能-筛选出二次检查的路由
 * @param routers
 * @param names
 * @returns {Array}
 */
function filterAuthRouter(routers) {
  return new Promise((resolve, reject) => {
    getHiddenAuth().then((toHiddenAuth) => {
      const filterPromises = routers.map(item => {
        if (toHiddenAuth.includes(item.name)) {
          item.hidden = true;
        }

        if (item.children) {
          return filterAuthRouter(item.children)
        } else {
          return Promise.resolve();
        }
      });

      Promise.all(filterPromises).then(() => {
        resolve();
      });
    });
  });
}

export default permission
