import request from '@/utils/request'

/**
 * 根据分类id 获取规格信息
 * @param category_id
 * @param params
 * @returns {Promise<any>}
 */
export function getCategorySkuList(category_id, params) {
  return request({
    // url: `seller/goods/categories/${category_id}/specs`,
    url: `seller/goods/${category_id}/specs`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 保存商家自定义某分类的规格项
 * @param category_id
 * @param params
 * @returns {Promise<any>}
 */
export function saveCustomSkuItem(category_id, params) {
  return request({
    // url: `seller/goods/categories/${category_id}/specs`,
    url: `seller/goods/specs`,
    method: 'put',
    loading: false,
    data: params
  })
}

/**
 * 保存商家自定义某规格的规格值
 * @param spec_id
 * @param params
 * @returns {Promise<any>}
 */
// export function saveCustomSkuValue(spec_id, params) {
//   return request({
//     url: `seller/goods/specs/${spec_id}/values`,
//     method: 'post',
//     loading: false,
//     data: params
//   })
// }



/**
 * 企业/供应商修改自定义规格
 * @param spec_id
 * @param params
 * @returns {Promise<any>}
 */
export function editCustomSkuItem(spec_id, params) {
  return request({
    url: `/seller/goods/specs/${spec_id}`,
    method: 'put',
    loading: false,
    data: params
  })
}
/**
 * 企业/供应商查询自定义规格值
 * @param params
 */
export function getSpecsValue(params) {
  return request({
    url: `/seller/goods/specsValue`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 企业/供应商修改自定义规格值
 * @param spec_id
 * @param params
 * @returns {Promise<any>}
 */
export function editCustomSkuValue(spec_value_id, params) {
  return request({
    url: `/seller/goods/specsValue/${spec_value_id}`,
    method: 'put',
    loading: false,
    data: params
  })
}
/**
 * 企业、供应商新增自定义某规格的规格值
 * @param spec_id
 * @param params
 * @returns {Promise<any>}
 */
export function saveCustomSkuValue(params) {
  return request({
    url: `/seller/goods/specsValue`,
    method: 'put',
    loading: false,
    data: params
  })
}